/** @jsxImportSource @emotion/react */

// Import Ant Design components
import { Collapse, Typography, Space } from 'antd';
import { EditOutlined, CheckCircleOutlined } from '@ant-design/icons';

// Import stylesheets
import colors from '../../../../styles/colors';

// Import additional Ant Design components
const { Panel } = Collapse;
const { Title, Text } = Typography;

const CollapsableCard = (props) => {
    // Extract values from props
    const { sectionKey, displayName, extra, active, onActiveChanged, styles, children } = props;

    return (
        <Collapse
            activeKey={active ? [sectionKey] : []}
            onChange={() => onActiveChanged(sectionKey)}
            expandIconPosition="right"
            css={styles}
        >
            <Panel
                header={
                    <Space>
                        {extra ? (
                            <CheckCircleOutlined style={{ color: colors.otsoForestGreen }} />
                        ) : (
                            <EditOutlined />
                        )}
                        <Title level={5} style={{ margin: 0 }}>
                            {displayName}
                        </Title>
                    </Space>
                }
                key={sectionKey}
                extra={extra ? <Text type="secondary">{extra}</Text> : null}
            >
                {children}
            </Panel>
        </Collapse>
    );
};

export default CollapsableCard;
