/** @jsxImportSource @emotion/react */

// Import libraries
import { useState, useEffect } from 'react';

// Import Ant Design components
import { Form, Select, message } from 'antd';

// Import modules
import { getApi } from '../../../modules/api';

// Import additional Ant Design components
const { Option } = Select;

const FacebookForm = ({ form, initialValues, integrationId }) => {
    // Initialisation
    const [fbPages, setFBPages] = useState([]);

    useEffect(() => {
        // Obtain authorised Facebook pages
        const getFBPages = async () => {
            try {
                const { data = {} } = await getApi(`integrations/${integrationId}`);
                setFBPages(data.fbPages || []);
            } catch (error) {
                message.error('Unable to obtain authorised Facebook pages');
            }
        };

        getFBPages();

        return () => setFBPages([]);
    }, [integrationId]);

    return (
        <Form name="facebookForm" form={form} initialValues={initialValues} layout="vertical">
            <Form.Item name="fbPages" label="Facebook Pages" rules={[{ required: true }]}>
                <Select mode="multiple" allowClear placeholder="Select Facebook pages">
                    {fbPages.map((fbPage) => (
                        <Option key={fbPage.id} value={fbPage.id}>
                            {fbPage.name}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        </Form>
    );
};

export default FacebookForm;
