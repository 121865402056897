/** @jsxImportSource @emotion/react */

// Import Ant Design components
import { Card } from 'antd';

// Import components
import IntegrationsTable from './IntegrationsTable';

// Import stylesheet
import styles from './styles';

const Integrations = () => {
    return (
        <Card style={styles.integrationsWrapper}>
            <IntegrationsTable allowCreate allowEdit allowedTypes={[]} filteredTypes={[]} />
        </Card>
    );
};

export default Integrations;
