// Import colors and presets
import colors from '../../../../styles/colors';
import { pageContentWrapper } from '../../../../styles/presets';

// Prepare a stylesheet
const styles = {
    createDatasetWrapper: {
        ...pageContentWrapper,
        minWidth: '700px',
    },
    breadcrumb: { marginBottom: '45px' },
    collapsableCard: {
        margin: '30px 0px',
        'div.ant-collapse-content-box': { padding: '20px 30px' },
    },
    nextButtonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '45px',
    },
    datasetSource: { margin: '30px 0px' },
    onSelected: {
        backgroundColor: colors.otsoForestGreen,
        borderColor: colors.otsoForestGreen,
    },
    actionPanel: { marginTop: '15px' },
};

export default styles;
