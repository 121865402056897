// Configure color settings
const colors = {
    // Primary colors
    primary: '#1C5FE6',

    // otso colors
    otsoBrightBlue: '#1C5FE6',
    otsoFadedBrightBlue: '#E4EAF6',
    otsoBlue: '#003F67',
    otsoYellowOrange: '#FFAA44',
    otsoPaleRed: '#FC9A92',
    otsoDustRed: '#FF5561',
    otsoPink: '#E536B5',
    otsoDark: '#2E3033',
    otsoGray: '#F0F4F6',
    otsoForestGreen: '#237804',
    otsoDarkGreen: '#044f50',
};

export default colors;
