/** @jsxImportSource @emotion/react */

// Import libraries
import dayjs from 'dayjs';
import { useState } from 'react';

// Import Ant Design components
import {
    Descriptions,
    Space,
    Avatar,
    Typography,
    Button,
    Form,
    Modal,
    message,
    Tooltip,
} from 'antd';
import { UserOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

// Import forms
import DatasetDetailsForm from '../../forms/DatasetDetailsForm';

// Import stylesheet
import { button as buttonStyles } from '../../../../styles/presets';
import styles from './styles';

// Import additional Ant Design components
const { confirm } = Modal;
const { Text } = Typography;

const DatasetDetails = (props) => {
    // Extract values from props
    const { dataset, users = {}, onUpdateDataset, onDeleteDataset, onArchiveDataset } = props;

    // Initialisation
    const [inEdit, setInEdit] = useState(false);
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [archiving, setArchiving] = useState(false);

    // Initialise form
    const [form] = Form.useForm();

    // On editing finished
    const onFinish = async () => {
        form.resetFields();
        setInEdit(false);
    };

    // On changes saved
    const onSave = async (values) => {
        try {
            setSaving(true);
            await onUpdateDataset(values);
            message.success('Dataset details updated');
            onFinish();
        } catch (error) {
            message.error('Unable to save the dataset details, please try again later');
        }
        setSaving(false);
    };

    // On deleting dataset
    const onDeletion = async () => {
        const modal = confirm({
            title: 'Do you want to delete this dataset?',
            icon: <ExclamationCircleOutlined />,
            content: 'Please note you cannot undo this action.',
            onOk: async () => {
                try {
                    setDeleting(true);
                    modal.update({ cancelButtonProps: { disabled: true } });
                    await onDeleteDataset();
                    message.success('Dataset deleted');
                } catch (error) {
                    setDeleting(false);
                    modal.update({ cancelButtonProps: { disabled: false } });
                    message.error('Unable to delete the dataset, please try again later');
                }
            },
        });
    };

    // On archiving dataset
    const onArchive = async () => {
        const modal = confirm({
            title: 'Do you want to archive this dataset?',
            icon: <ExclamationCircleOutlined />,
            content: 'Please note data will not be deleted with this action.',
            onOk: async () => {
                try {
                    setArchiving(true);
                    modal.update({ cancelButtonProps: { disabled: true } });
                    await onArchiveDataset();
                    message.success('Dataset archived');
                } catch (error) {
                    setArchiving(false);
                    modal.update({ cancelButtonProps: { disabled: false } });
                    message.error('Unable to archive the dataset, please try again later');
                }
            },
        });
    };

    return (
        <>
            {/* Dataset details */}
            {inEdit ? (
                <DatasetDetailsForm
                    form={form}
                    onFinish={onSave}
                    initialValues={{
                        name: dataset.name,
                        description: dataset.description,
                    }}
                />
            ) : (
                <Descriptions bordered column={1} labelStyle={styles.descriptionLabel}>
                    {/* Dataset name */}
                    <Descriptions.Item label="Name">{dataset.name || '-'}</Descriptions.Item>

                    {/* Dataset description */}
                    <Descriptions.Item label="Description">
                        {dataset.description || '-'}
                    </Descriptions.Item>

                    {/* Dataset creation time */}
                    <Descriptions.Item label="Created At">
                        {dayjs(dataset.createdAt).format('MMM DD, YYYY') || '-'}
                    </Descriptions.Item>

                    {/* Dataset creator */}
                    <Descriptions.Item label="Created By">
                        <Space>
                            <Avatar
                                size="small"
                                src={
                                    dataset.createdBy
                                        ? (users[dataset.createdBy] || {}).avatar
                                        : null
                                }
                                icon={<UserOutlined />}
                            />
                            <Text>
                                {dataset.createdBy
                                    ? (users[dataset.createdBy] || {}).name || '-'
                                    : null}
                            </Text>
                        </Space>
                    </Descriptions.Item>
                </Descriptions>
            )}

            {/* Action panel */}
            <div style={styles.actionPanel}>
                {inEdit ? (
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => form.submit()}
                            loading={saving}
                            style={buttonStyles}
                        >
                            Save
                        </Button>
                        <Button onClick={onFinish} disabled={saving} style={buttonStyles}>
                            Cancel
                        </Button>
                    </Space>
                ) : (
                    <Space>
                        <Button type="primary" onClick={() => setInEdit(true)} style={buttonStyles}>
                            Edit
                        </Button>
                        <Tooltip title="Dataset and data will be permanently deleted.">
                            <Button
                                type="danger"
                                onClick={onDeletion}
                                loading={deleting}
                                disabled={!dataset.isReady}
                                style={buttonStyles}
                            >
                                Delete
                            </Button>
                        </Tooltip>
                        <Tooltip title="Dataset will be archived, and you can unarchive later.">
                            <Button
                                type="danger"
                                onClick={onArchive}
                                loading={archiving}
                                disabled={!dataset.isReady}
                                style={buttonStyles}
                            >
                                Archive
                            </Button>
                        </Tooltip>
                    </Space>
                )}
            </div>
        </>
    );
};

export default DatasetDetails;
