/** @jsxImportSource @emotion/react */

// Import libraries
import dayjs from 'dayjs';
import { useState } from 'react';

// Import Ant Design components
import { Row, Col, Statistic, Typography, Space, Button } from 'antd';

// Import components
import VersionExport from '../VersionExport';

// Import stylesheet
import styles from './styles';

// Import additional Ant Design componnets
const { Title, Paragraph } = Typography;

const VersionDetails = (props) => {
  // Extract values from props
  const { version = {} } = props;

  // Initialisation
  const [exportVisible, setExportVisible] = useState(false);

  return (
    <>
      {/* Version metadata */}
      <Title level={5}>Metadata:</Title>
      <Row gutter={16}>
        {/* Number of documents
                <Col span={12}>
                    <Statistic title="Number of documents" value={version.numOfDocuments || '-'} />
                </Col> */}

        {/* Version creation time */}
        <Col span={12}>
          <Statistic
            title="Created At"
            value={dayjs(version.createdAt).format('MMM DD, YYYY') || '-'}
          />
        </Col>
      </Row>

      {/* Version description */}
      <Title level={5} style={styles.versionDescription}>
        Description:
      </Title>
      <Paragraph>{version.description || '-'}</Paragraph>

      {/* Action panel */}
      <Space style={styles.actionPanel}>
        <Button type="primary">Edit version</Button>
        <Button type="secondary" onClick={() => setExportVisible(true)}>
          Export
        </Button>
        <Button type="danger">Delete</Button>
      </Space>

      {/* Version export */}
      <VersionExport visible={exportVisible} />
    </>
  );
};

export default VersionDetails;
