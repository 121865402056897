/** @jsxImportSource @emotion/react */

// Import Ant Design components
import { Alert, Divider, Form, Typography, Switch } from 'antd';

// Import components
import DatasetIntegration from '../DatasetIntegration';

// Import forms
import EnrichmentFieldConfigsForm from '../../forms/EnrichmentFieldConfigsForm';
import VertexEndpoints from '../VertexEndpoints';

// Import additional Ant Design components
const { Title } = Typography;

const DataEnrichments = (props) => {
  // Extract values from props
  const {
    form,
    selectedEnrichment,
    setSelectedEnrichment,
    selectedSource,
    fieldConfigs,
    vertexEndpoints,
    setVertexEndpoints,
  } = props || {};

  // Extract values from selected source
  const { type: sourceType } = selectedSource || {};

  if (sourceType !== 'integration' && !fieldConfigs) {
    return (
      <Alert
        message="No Source Selected"
        description="Please select a data source before configure the data enrichments."
        type="warning"
        showIcon
      />
    );
  }

  return (
    <>
      {/* Verbatim field configurations */}
      {sourceType !== 'integration' && (
        <>
          <EnrichmentFieldConfigsForm form={form} fieldConfigs={fieldConfigs} />

          <Divider />
        </>
      )}

      {sourceType === 'integration' && (
        <Form form={form} labelAlign="left" labelCol={{ span: 6 }} wrapperCol={{ span: 11 }}>
          <Form.Item name="shouldGeolocateOnIntegrations" label="Enable Geo-locate addresses">
            <Switch checkedChildren="Enabled" unCheckedChildren="Disabled" />
          </Form.Item>
          <Divider />
        </Form>
      )}

      {/* Dataset integration selection */}
      <Title level={5}>Enrichment Integration Selection</Title>
      <VertexEndpoints vertexEndpoints={vertexEndpoints} setVertexEndpoints={setVertexEndpoints} />
      <DatasetIntegration
        selectedSource={selectedEnrichment.target}
        onSelectedSourceChange={(id, name, type) => {
          setSelectedEnrichment({
            target: id,
            name,
            type: 'integration',
            integrationType: type,
          });
        }}
        allowedTypes={['otsoDeploy']}
      />
    </>
  );
};

export default DataEnrichments;
