/** @jsxImportSource @emotion/react */

// Import libraries
import dayjs from 'dayjs';
import { find } from 'lodash';
import { useState } from 'react';
import relativeTime from 'dayjs/plugin/relativeTime';

// Import Ant Design components
import {
    Table,
    Empty,
    Card,
    Space,
    Typography,
    Dropdown,
    Menu,
    Avatar,
    Tooltip,
    Row,
    Col,
} from 'antd';
import { LoadingOutlined, CheckCircleTwoTone, MoreOutlined, UserOutlined } from '@ant-design/icons';

// Import components
import VersionDetails from '../VersionDetails';
import VersionPreview from '../VersionPreview';
import VersionProfile from '../VersionProfile';
import VersionMetadata from '../VersionMetadata';

// Import stylesheet
import styles from './styles';

// Add relative time plugin
dayjs.extend(relativeTime);

// Import additional Ant Design components
const { Text } = Typography;

const DatasetVersions = (props) => {
    // Extract values from props
    const { type, versions = [], users = {} } = props;

    // Find the current dataset version
    const currentVersion = find(versions, { isCurrentVersion: true }) || versions[0];

    // Initialisation
    const [selectedVersion, setSelectedVersion] = useState(currentVersion);
    const [currentTab, setCurrentTab] = useState('details');
    // const [documents, setDocuments] = useState([]);
    // const [loadingDocs, setLoadingDocs] = useState(false);

    // Obtain a Firestore reference to the selected version
    // const { versionRef } = selectedVersion || {};

    // useEffect(() => {
    //     const fetchDocuments = async () => {
    //         try {
    //             setLoadingDocs(true);

    //             // Retrieve documents within the currently selected version
    //             const docsRef = await versionRef.collection('documents');
    //             const docsSnapshot = await docsRef.limit(10).get();

    //             // Update documents
    //             const docs = docsSnapshot.docs.map((doc) => doc.data());
    //             setDocuments(docs);
    //         } catch (error) {
    //             message.error('Unable to fetch documents on the selected version');
    //         } finally {
    //             setLoadingDocs(false);
    //         }
    //     };

    //     if (versionRef) fetchDocuments();

    //     return () => {
    //         setDocuments([]);
    //         setLoadingDocs(false);
    //     };
    // }, [versionRef]);

    // Prepare dropdown menu
    const menu = (
        <Menu>
            <Menu.Item key="setPrimary">Set primary</Menu.Item>
        </Menu>
    );

    // Prepare version table columns
    const columns = [
        {
            dataIndex: 'isReady',
            key: 'isReady',
            width: '15%',
            render: (isReady, version) =>
                isReady ? (
                    <CheckCircleTwoTone
                        twoToneColor={version.isCurrentVersion ? '#52c41a' : '#bfbfbf'}
                    />
                ) : (
                    <LoadingOutlined />
                ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sortDirections: ['descend'],
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.name - b.name,
        },
        {
            title: 'Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => dayjs(createdAt).fromNow(),
        },
        {
            dataIndex: 'id',
            key: 'id',
            width: '15%',
            render: () => (
                <Dropdown overlay={menu} arrow>
                    <MoreOutlined style={{ cursor: 'pointer' }} />
                </Dropdown>
            ),
        },
    ];

    // Prepare tab list
    const tabList = [
        { key: 'details', tab: 'Version Details' },
        { key: 'preview', tab: 'Data Preview' },
        { key: 'profile', tab: 'Data Profile' },
        { key: 'metadata', tab: 'Metadata' },
    ];

    return (
        <Row style={styles.row}>
            {/* Version list */}
            <Col span={6}>
                <Table
                    dataSource={versions}
                    rowKey="id"
                    size="middle"
                    columns={columns}
                    pagination={false}
                    locale={{
                        emptyText: <Empty description="No datasets found" style={styles.empty} />,
                    }}
                    rowSelection={{
                        type: 'radio',
                        selectedRowKeys: [selectedVersion.id],
                        onChange: (selectedRowKeys) => {
                            versions.forEach((version) => {
                                if (version.id === selectedRowKeys[0]) {
                                    setSelectedVersion(version);
                                }
                            });
                        },
                    }}
                    style={styles.versionList}
                />
            </Col>

            {/* Version details */}
            <Col span={18}>
                <Card
                    title={
                        <Space>
                            {selectedVersion.isReady ? (
                                <CheckCircleTwoTone twoToneColor="#52c41a" />
                            ) : (
                                <LoadingOutlined />
                            )}
                            <Text>Version {selectedVersion.name}</Text>
                        </Space>
                    }
                    extra={
                        <Tooltip
                            title={
                                selectedVersion.createdBy
                                    ? (users[selectedVersion.createdBy] || {}).name || '-'
                                    : null
                            }
                        >
                            <Avatar
                                size="small"
                                src={
                                    selectedVersion.createdBy
                                        ? (users[selectedVersion.createdBy] || {}).avatar
                                        : null
                                }
                                icon={<UserOutlined />}
                            />
                        </Tooltip>
                    }
                    size="middle"
                    tabProps={{ size: 'middle' }}
                    tabList={tabList}
                    activeTabKey={currentTab}
                    onTabChange={(key) => setCurrentTab(key)}
                    bodyStyle={currentTab === 'preview' ? { padding: '1px 0px 0px' } : {}}
                    style={styles.versionDetails}
                >
                    {/* Version details */}
                    {currentTab === 'details' && <VersionDetails version={selectedVersion} />}

                    {/* Version preview */}
                    {currentTab === 'preview' && (
                        <VersionPreview type={type} documents={[]} loading />
                    )}

                    {/* Version profile */}
                    {currentTab === 'profile' && <VersionProfile />}

                    {/* Version metadata */}
                    {currentTab === 'metadata' && <VersionMetadata />}
                </Card>
            </Col>
        </Row>
    );
};

export default DatasetVersions;
