/** @jsxImportSource @emotion/react */

// Import Ant Design components
import { Card, Typography } from 'antd';

// Import stylesheet
import styles from './styles';

// Import additional Ant Design components
const { Title } = Typography;

const Dashboard = () => {
    return (
        <Card style={styles.dashboardWrapper}>
            <Title level={4}>Welcome to otso Data!</Title>
        </Card>
    );
};

export default Dashboard;
