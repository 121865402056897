/** @jsxImportSource @emotion/react */

// Import Ant Design components
import { Spin } from 'antd';

// Import components
import JSONPreview from '../../../../components/JSONPreview';
import EmailPreview from '../../../../components/EmailPreview';

const VersionPreview = props => {
    // Extract values from props
    const { type, documents, loading } = props;

    // Display a spinner if documents are being fetched
    if (loading) return (<Spin spinning={loading} />);

    if (type === 'email') {
        // Display email data preview
        return (
            <EmailPreview data={documents} />
        );
    }

    return (<JSONPreview data={documents} />);
};

export default VersionPreview;
