/** @jsxImportSource @emotion/react */

// Import libraries
import urlJoin from 'url-join';
import { useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';

// Import Ant Design components
import { Card, Space, Typography, Button, message } from 'antd';
import { LoadingOutlined, CheckCircleTwoTone, LeftOutlined } from '@ant-design/icons';

// Import modules
import { getApi, postApi, deleteApi, putApi } from '../../modules/api';

// Import components
import DatasetDetails from './components/DatasetDetails';
import DatasetSource from './components/DatasetSource';
import DatasetVersions from './components/DatasetVersions';
import DatasetLogs from './components/DatasetLogs';

// Import stylesheets
import styles from './styles';

// Import additional Ant Design components
const { Text } = Typography;

const Dataset = (props) => {
    // Extract dataset slug from path parameters
    const { datasetSlug } = props.match.params;

    // Initialisation
    const history = useHistory();
    const [dataset, setDataset] = useState({});
    const [loadingDataset, setLoadingDataset] = useState(false);
    const [datasetVersions, setDatasetVersions] = useState([]);
    const [currentTab, setCurrentTab] = useState('details');
    const [dataFetchedAt, setDataFetchedAt] = useState(new Date().getTime());
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchDataset = async () => {
            try {
                // Initialisation
                setLoadingDataset(true);

                // Send a request to obtain dataset details and dataset versions
                const { data = {} } = await getApi(`datasets/${datasetSlug}`);

                setUsers(data.users || {});
                setDataset(data.dataset || {});
                setDatasetVersions(data.datasetVersions || []);
            } catch (error) {
                message.error('Unable to fetch dataset details, please try again later');
            } finally {
                setLoadingDataset(false);
            }
        };

        if (datasetSlug) fetchDataset();
    }, [datasetSlug, dataFetchedAt]);

    // Refresh dataset and dataset versions data
    const refreshData = () => setDataFetchedAt(new Date().getTime());

    // Create a new dataset version
    const createDatasetVersion = async (description) => {
        await postApi(urlJoin('datasets', datasetSlug, 'versions'), {
            description,
        });

        // Refresh dataset versions
        refreshData();
    };

    // On updating the dataset
    const onUpdateDataset = async (data) => {
        try {
            await putApi(`datasets/${datasetSlug}`, data);
            setDataset({ ...dataset, ...data });
        } catch (error) {
            message.error('Unable to update dataset, please try again later');
        }
    };

    // On deleting the dataset
    const onDeleteDataset = async () => {
        try {
            // Delete all dataset versions and associated Cloud Scheduler
            await deleteApi(urlJoin('datasets', datasetSlug), { mode: 'delete' });

            // Redirect to datasets page
            history.push('/datasets');
        } catch (error) {
            message.error('Unable to delete dataset, please try again later');
        }
    };

    // On archiving the dataset
    const onArchiveDataset = async () => {
        try {
            // Archive dataset and delete associated Cloud Scheduler
            await deleteApi(urlJoin('datasets', datasetSlug), { mode: 'archive' });

            // Redirect to datasets page
            history.push('/datasets');
        } catch (error) {
            message.error('Unable to archive dataset, please try again later');
        }
    };

    // Prepare tab list
    const tabList = [
        { key: 'details', tab: 'Details' },
        { key: 'versions', tab: 'Versions' },
        ...(dataset.source ? [{ key: 'source', tab: 'Source' }] : []),
        { key: 'logs', tab: 'Logs' },
    ];

    return (
        <Card
            title={
                <Space>
                    {dataset.isReady ? (
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                    ) : (
                        <LoadingOutlined />
                    )}
                    <Text>{dataset.name}</Text>
                </Space>
            }
            loading={loadingDataset}
            tabList={tabList}
            activeTabKey={currentTab}
            onTabChange={(key) => setCurrentTab(key)}
            extra={
                <Button
                    type="text"
                    icon={<LeftOutlined />}
                    onClick={() => history.push('/datasets')}
                />
            }
            style={styles.datasetWrapper}
            bodyStyle={{
                ...styles.datasetWrapperBody,
                ...(currentTab === 'versions' ? styles.versionWrapperBody : {}),
            }}
        >
            {/* Dataset details */}
            {currentTab === 'details' && (
                <DatasetDetails
                    dataset={dataset}
                    users={users}
                    onUpdateDataset={onUpdateDataset}
                    onDeleteDataset={onDeleteDataset}
                    onArchiveDataset={onArchiveDataset}
                />
            )}

            {/* Dataset versions */}
            {currentTab === 'versions' && (
                <DatasetVersions type={dataset.type} versions={datasetVersions} users={users} />
            )}

            {/* Dataset source */}
            {currentTab === 'source' && (
                <DatasetSource
                    datasetSlug={datasetSlug}
                    type={dataset.type}
                    sourceType={dataset.sourceType}
                    source={dataset.source}
                    onUpdateDataset={onUpdateDataset}
                    createDatasetVersion={createDatasetVersion}
                />
            )}

            {/* Dataset logs */}
            {currentTab === 'logs' && <DatasetLogs />}
        </Card>
    );
};

export default Dataset;
