export const button = {
    minWidth: '100px',
    borderRadius: '4px',
};

export const input = {
    borderRadius: '4px',
};

export const card = {
    borderRadius: '8px',
};

export const cardBody = {
    padding: '48px 40px 36px',
};

export const pageContentWrapper = {
    width: '80%',
    margin: '50px auto',
};
