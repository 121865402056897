/** @jsxImportSource @emotion/react */

// Import libraries
import { useState, useEffect } from 'react';

// Import Ant Design components
import { Button, Space, Table, Tooltip, Input, Empty, message } from 'antd';
import { ReloadOutlined, SearchOutlined } from '@ant-design/icons';

// Import modules
import { getApi } from '../../modules/api';

// Import components
import Integration from './Integration';

// Import constants
import { getIntegrationDisplayTypeName } from '../../constants/integrations';

// Import stylesheet
import { button as buttonStyles } from '../../styles/presets';
import styles from './styles';

const IntegrationsTable = (props) => {
    // Extract values from props
    const {
        allowCreate = false,
        rowSelection = null,
        allowEdit = false,
        headerStyles = {},
        allowedTypes = [],
        filteredTypes = [],
    } = props;

    // Initialisation
    const [integrations, setIntegrations] = useState([]);
    const [loadingIntegrations, setLoadingIntegrations] = useState(false);
    const [refreshedAt, setRefreshedAt] = useState(new Date().getTime());
    const [searchValue, setSearchValue] = useState(null);
    const [integrationModalVisible, setIntegrationModalVisible] = useState(false);
    const [selectedIntegrationId, setSelectedIntegrationId] = useState(null);

    useEffect(() => {
        // Retrieve all organisation integrations
        const getOrgIntegrations = async () => {
            try {
                setLoadingIntegrations(true);

                // Initialisation
                const orgIntegrations = [];

                // Send a request to fetch all integrations
                const { data = [] } = await getApi('integrations');

                // Apply filters
                data.forEach((integration) => {
                    if (
                        (allowedTypes.length > 0 && allowedTypes.indexOf(integration.type) >= 0) ||
                        (filteredTypes.length > 0 && filteredTypes.indexOf(integration.type) < 0) ||
                        (allowedTypes.length === 0 && filteredTypes.length === 0)
                    ) {
                        orgIntegrations.push(integration);
                    }
                });

                setIntegrations(orgIntegrations);
            } catch (error) {
                message.error('Unable to retrieve integrations, please try again later');
            } finally {
                setLoadingIntegrations(false);
            }
        };

        getOrgIntegrations();
    }, [refreshedAt, allowedTypes, filteredTypes]);

    /** Refresh integrations list. */
    const refreshIntegrations = () => setRefreshedAt(new Date().getTime());

    // Prepare integrations table columns
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '30%',
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (name, integration) => {
                return (
                    <Button
                        type={allowEdit ? 'link' : 'text'}
                        onClick={() => {
                            if (allowEdit) {
                                setSelectedIntegrationId(integration.id.trim());
                                setIntegrationModalVisible(true);
                            }
                        }}
                    >
                        {name || '-'}
                    </Button>
                );
            },
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            responsive: ['lg'],
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: '20%',
            responsive: ['md'],
            sorter: (a, b) => a.type.localeCompare(b.type),
            render: (type) => getIntegrationDisplayTypeName(type) || '-',
        },
    ];

    return (
        <>
            {/* Header */}
            <div style={{ ...styles.header, ...headerStyles }}>
                <Space>
                    <Input
                        placeholder="Search integration"
                        prefix={<SearchOutlined />}
                        bordered={false}
                        onChange={(e) => setSearchValue(e.target.value)}
                        allowClear
                    />
                    {allowCreate && (
                        <Button
                            type="primary"
                            onClick={() => setIntegrationModalVisible(true)}
                            style={buttonStyles}
                        >
                            Create Integration
                        </Button>
                    )}
                    <Tooltip title="Refresh">
                        <Button
                            type="text"
                            icon={<ReloadOutlined />}
                            onClick={refreshIntegrations}
                        />
                    </Tooltip>
                </Space>
            </div>

            {/* Integrations table */}
            <Table
                dataSource={
                    searchValue
                        ? integrations.filter((item) => item.name.indexOf(searchValue) >= 0)
                        : integrations
                }
                rowKey="id"
                loading={loadingIntegrations}
                columns={columns}
                rowSelection={rowSelection}
                locale={{
                    emptyText: <Empty description="No integrations found" style={styles.empty} />,
                }}
            />

            {/* Modal for creating or editing integration */}
            <Integration
                integrationId={selectedIntegrationId}
                visible={integrationModalVisible}
                onClose={() => {
                    setIntegrationModalVisible(false);
                    setSelectedIntegrationId(null);
                }}
                refreshIntegrations={refreshIntegrations}
            />
        </>
    );
};

export default IntegrationsTable;
