/** @jsxImportSource @emotion/react */

// Import Ant Design components
import { Tabs } from 'antd';

// Import components
import DatasetIntegration from '../DatasetIntegration';
import FileBrowser from '../../../../components/FileBrowser';

// Import additional Ant Design components
const { TabPane } = Tabs;

// Extract values from environment variables
const { REACT_APP_GCS_ORG_BUCKET_PREFIX } = process.env;

const SourceSelection = (props) => {
    // Extract values from props
    const {
        currentOrg,
        datasetType,
        selectedSource,
        setSelectedSource,
        tabPosition = 'top',
        tabBarStyle = {},
        defaultActiveKey = 'files',
        initDirPath = 'data',
        allowedIntegrationTypes = [],
        filteredIntegrationTypes = [],
    } = props;

    return (
        <Tabs
            tabPosition={tabPosition}
            tabBarStyle={tabBarStyle}
            defaultActiveKey={defaultActiveKey}
        >
            {/* Select source from a file browser */}
            <TabPane tab="Files" key="files">
                <FileBrowser
                    bucket={currentOrg ? REACT_APP_GCS_ORG_BUCKET_PREFIX + currentOrg : null}
                    initDirPath={initDirPath}
                    rowSelection={{
                        type: 'radio',
                        selectedRowKeys: [selectedSource.target],
                        onChange: (selectedKeys, selectedRows) => {
                            setSelectedSource({
                                target: selectedKeys[0],
                                name: selectedKeys[0],
                                type: selectedRows[0].contentType,
                            });
                        },
                    }}
                    headerStyles={{ paddingTop: 0 }}
                />
            </TabPane>

            {/* Select source from the integration list */}
            <TabPane tab="Integrations" key="integrations" disabled={datasetType === 'other'}>
                <DatasetIntegration
                    selectedSource={selectedSource.target}
                    onSelectedSourceChange={(id, name, type) => {
                        setSelectedSource({
                            target: id,
                            name,
                            type: 'integration',
                            integrationType: type,
                        });
                    }}
                    allowedTypes={allowedIntegrationTypes}
                    filteredTypes={filteredIntegrationTypes}
                />
            </TabPane>
        </Tabs>
    );
};

export default SourceSelection;
