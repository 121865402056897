/** @jsxImportSource @emotion/react */

// Import libraries
import dayjs from 'dayjs';
import { useState } from 'react';
import dompurify from 'dompurify';
import { Parser as HtmlToReactParser } from 'html-to-react';

// Import Ant Design components
import { Row, Col, Table, Empty, Typography, Card, Avatar } from 'antd';

// Import stylesheets
import styles from './styles';

// Import additional Ant Design components
const { Text, Title } = Typography;

const EmailPreview = (props) => {
  // Extract values from props
  const { data = [] } = props;

  // Initialisation
  const [selectedEmail, setSelectedEmail] = useState(data[0] || {});

  // Obtain the selected email content
  const { uniqueBody } = selectedEmail;
  const { content } = uniqueBody || {};

  // Clean the email content and parse into a React component
  const cleanContent = dompurify.sanitize(content, { USE_PROFILES: { html: true } });
  const parsedCleanContent = new DOMParser().parseFromString(cleanContent, 'text/html');
  const htmlToReactParser = new HtmlToReactParser();
  const emailContent = htmlToReactParser.parse(parsedCleanContent.body.innerHTML);

  // Prepare email list columns
  const columns = [
    {
      dataIndex: 'id',
      key: 'id',
      render: (_, email) => {
        const { sender, subject = 'No Subject', bodyPreview = '', createdDateTime } = email || {};
        const { emailAddress } = sender || {};
        const { name, address } = emailAddress || {};
        const senderInfo = name || address || 'Unknown';
        return (
          <div style={styles.emailListItem}>
            <div style={styles.emailListAvatar}>
              <Avatar size="middle">{senderInfo[0]}</Avatar>
            </div>
            <div style={styles.emailListItemInfo}>
              <Text strong>{senderInfo}</Text>
              <div style={styles.subject}>
                <Text>{subject.slice(0, 10)}</Text>
                <Text>{dayjs(createdDateTime).format('DD/MM/YYYY')}</Text>
              </div>
              <Text type="secondary">{bodyPreview.slice(0, 30)}</Text>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Row>
        {/* Email list */}
        <Col span={8}>
          <Table
            dataSource={data}
            rowKey="id"
            size="middle"
            columns={columns}
            bordered
            pagination={false}
            showHeader={false}
            locale={{ emptyText: <Empty description="No emails found" style={styles.empty} /> }}
            onRow={(_, idx) => ({
              onClick: () => setSelectedEmail(data[idx]),
            })}
            rowClassName={(record) =>
              record.id === selectedEmail.id ? 'highlighted-table-row' : null
            }
            css={styles.emailsTable}
          />
        </Col>

        {/* Email details */}
        <Col span={16} style={styles.emailDetails}>
          <Title level={5}>{selectedEmail.subject}</Title>
          <Card>{emailContent}</Card>
        </Col>
      </Row>
    </div>
  );
};

export default EmailPreview;
