/** @jsxImportSource @emotion/react */

// Import libraries
import dayjs from 'dayjs';
import { Link, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';

// Import Ant Design components
import {
    Card,
    Button,
    Space,
    Table,
    Tooltip,
    Input,
    Empty,
    Avatar,
    Typography,
    message,
} from 'antd';
import { ReloadOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';

// Import modules
import { getApi } from '../../modules/api';

// Import stylesheet
import { button as buttonStyles } from '../../styles/presets';
import styles from './styles';

// Import additional Ant Design components
const { Text } = Typography;

const Datasets = () => {
    // Initialisation
    const history = useHistory();
    const [datasets, setDatasets] = useState([]);
    const [loadingDatasets, setLoadingDatasets] = useState(false);
    const [refreshedAt, setRefreshedAt] = useState(new Date().getTime());
    const [searchValue, setSearchValue] = useState(null);

    useEffect(() => {
        // Retrieve all organisation datasets
        const getOrgDatasets = async () => {
            try {
                setLoadingDatasets(true);
                const { data: orgDatasets = [] } = await getApi('datasets');
                setDatasets(orgDatasets);
            } catch (error) {
                message.error('Unable to retrieve datasets, please try again later');
            } finally {
                setLoadingDatasets(false);
            }
        };

        getOrgDatasets();
    }, [refreshedAt]);

    /** Refresh datasets list. */
    const refreshDatasets = () => setRefreshedAt(new Date().getTime());

    // Prepare datasets table columns
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (name, dataset) => (
                <Link to={`/datasets/${dataset.id.trim()}`}>{name || '-'}</Link>
            ),
        },
        {
            title: 'Current Version',
            dataIndex: 'currentVersion',
            key: 'currentVersion',
            width: '15%',
            responsive: ['md'],
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '20%',
            defaultSortOrder: 'descend',
            sorter: (a, b) => (dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? -1 : 1),
            render: (createdAt) => dayjs(createdAt).format('MMM DD, YYYY'),
        },
        {
            title: 'Created By',
            dataIndex: 'creatorName',
            key: 'creatorName',
            width: '15%',
            responsive: ['md'],
            sorter: (a, b) => a.creatorName.localeCompare(b.creatorName),
            render: (creatorName, dataset) => (
                <Space>
                    <Avatar size="small" src={dataset.creatorAvatar} icon={<UserOutlined />} />
                    <Text>{creatorName || '-'}</Text>
                </Space>
            ),
        },
    ];

    return (
        <Card style={styles.datasetsWrapper}>
            {/* Header */}
            <div style={styles.header}>
                <Space>
                    <Input
                        placeholder="Search dataset"
                        prefix={<SearchOutlined />}
                        bordered={false}
                        onChange={(e) => setSearchValue(e.target.value)}
                        allowClear
                    />
                    <Button
                        type="primary"
                        onClick={() => history.push('/datasets/create')}
                        style={buttonStyles}
                    >
                        Create Dataset
                    </Button>
                    <Tooltip title="Refresh">
                        <Button type="text" icon={<ReloadOutlined />} onClick={refreshDatasets} />
                    </Tooltip>
                </Space>
            </div>

            {/* Datasets table */}
            <Table
                dataSource={
                    searchValue
                        ? datasets.filter((item) => item.name.indexOf(searchValue) >= 0)
                        : datasets
                }
                rowKey="id"
                loading={loadingDatasets}
                columns={columns}
                locale={{
                    emptyText: <Empty description="No datasets found" style={styles.empty} />,
                }}
            />
        </Card>
    );
};

export default Datasets;
