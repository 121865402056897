// Prepare a stylesheet
const styles = {
    layout: { minHeight: '100vh' },
    header: {
        position: 'fixed',
        zIndex: 1,
        width: '100%',
    },
    logo: { height: '30px' },
    content: { padding: '0 50px', marginTop: 64 },
    shareWrapper: {
        width: '50%',
        margin: '50px auto',
        display: 'flex',
        flexDirection: 'column',
        'div.ant-card': { marginBottom: '30px' },
    },
    footer: {
        textAlign: 'center',
    },
};

export default styles;
