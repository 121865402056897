// Import presets
import { pageContentWrapper } from '../../../../styles/presets';

// Prepare a stylesheet
const styles = {
  integrationsWrapper: {
    ...pageContentWrapper,
    minWidth: '700px',
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '15px 0px',
  },
  empty: { padding: '10px 0px' },
};

export default styles;
