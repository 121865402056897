// Import libraries
import ReactDOM from 'react-dom';
import 'firebase/auth';
import 'firebase/storage';
import { Global } from '@emotion/react';
import { Switch, Redirect, Route, RouteProps } from 'react-router-dom';
import {
  AuthWrapper,
  initAuth,
  useAuth,
  TAllActionValues,
  DataActions,
} from '@otso/auth-wrapper';

// Import Ant Design components
import { ConfigProvider } from 'antd';
import enGB from 'antd/lib/locale/en_GB';

// Import dashboard view
import Dashboard from './views/Dashboard';

// Import files view
import Files from './views/Files';

// Import dataset views
import Datasets from './views/Datasets';
import Dataset from './views/Dataset';
import CreateDataset from './views/Dataset/components/CreateDataset';

// Import integration views
import Integrations from './views/Integrations';

// Import activity views
import Activities from './views/Activities';
import Activity from './views/Activity';

// Import Share views
import Share from './views/Share';

// Import stylesheets
import globals from './styles/globals';
import colors from './styles/colors';

// Import antd styles
import 'antd/dist/antd.less';

initAuth({
  product: 'data',
  firebaseConfig: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  },
  authAPIHost: process.env.REACT_APP_AUTH_API_URL,
  authHost: process.env.REACT_APP_AUTH_CLIENT_URL,
});

interface CustomRouteProps extends RouteProps {
  action: TAllActionValues;
}

const PermissionRoute: React.FC<CustomRouteProps> = ({ children, action, ...restProps }) => {
  const { user } = useAuth();

  return (
    <Route {...restProps}>
      {user?.actions?.[action] ? children : <Redirect to={{ pathname: '/' }} />}
    </Route>
  );
};

const Organisation = () => {
  return (
    <Switch>
      {/* Dashboard page */}
      <Route exact path="/dashboard" component={Dashboard} />

      {/* Files page */}
      <PermissionRoute
        exact
        path="/storage/browser"
        component={Files}
        action={DataActions.manageFiles}
      />

      <PermissionRoute path="/datasets" action={DataActions.manageDatasets}>
        <Switch>
          {/* Datasets page */}
          <Route exact path="/datasets" component={Datasets} />
          <Route exact path="/datasets/create" component={CreateDataset} />
          <Route exact path="/datasets/:datasetSlug" component={Dataset} />
        </Switch>
      </PermissionRoute>

      {/* Integrations page */}
      <PermissionRoute
        exact
        path="/integrations"
        component={Integrations}
        action={DataActions.manageIntegrations}
      />

      {/* Activity page */}
      <Route exact path="/activities" component={Activities} />
      <Route exact path="/activities/:activityId" component={Activity} />

      {/* Redirect for unmatched routes */}
      <Redirect to="/dashboard" />
    </Switch>
  );
};

const App = () => {
  return (
    <AuthWrapper
      logo="/logo/otso_mark.png"
      primaryColor={colors.primary}
      customRoutes={[{ path: '/share/:shareId', component: Share }]}
      navMenu={[
        // { path: '/dashboard', title: 'Dashboard' },
        { path: '/storage/browser', title: 'Files', requiredAction: DataActions.manageFiles },
        { path: '/datasets', title: 'Datasets', requiredAction: DataActions.manageDatasets },
        {
          path: '/integrations',
          title: 'Integrations',
          requiredAction: DataActions.manageIntegrations,
        },
        // { path: '/activities', title: 'Activities' },
      ]}
    >
      <Global styles={globals} />
      <ConfigProvider locale={enGB}>
        <Route path="/" component={Organisation} />
      </ConfigProvider>
    </AuthWrapper>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
