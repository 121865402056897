/** @jsxImportSource @emotion/react */

// Import Ant Design components
import { Form, Input } from 'antd';

const DatasetDetailsForm = ({
    form,
    onFinish,
    onValuesChange,
    initialValues = {},
    disabledFields = [],
}) => {
    return (
        <Form
            name="datasetDetailsForm"
            form={form}
            onFinish={onFinish}
            onValuesChange={onValuesChange}
            layout="vertical"
            initialValues={initialValues}
        >
            {/* Dataset name */}
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                <Input
                    placeholder="Enter dataset name"
                    disabled={disabledFields.indexOf('name') >= 0}
                />
            </Form.Item>

            {/* Dataset description */}
            <Form.Item name="description" label="Description">
                <Input.TextArea
                    rows={3}
                    placeholder="Enter dataset description"
                    disabled={disabledFields.indexOf('description') >= 0}
                />
            </Form.Item>
        </Form>
    );
};

export default DatasetDetailsForm;
