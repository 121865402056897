/** @jsxImportSource @emotion/react */

// Import libraries
import { nanoid } from 'nanoid';

// Import Ant Design components
import { Divider, Spin, Alert, Tooltip, Table, Descriptions, Typography } from 'antd';

// Import components
import DataFieldAnalysis from '../../DataFieldAnalysis';

// Import forms
import IntegrationConfigsForm from '../../../forms/IntegrationConfigsForm';
import DatashakeForm from '../../../forms/DatashakeForm';
import RedditForm from '../../../forms/RedditForm';
import FacebookForm from '../../../forms/FacebookForm';
import GenesysForm from '../../../forms/GenesysForm';
import OutlookApplicationForm from '../../../forms/OutlookApplicationForm';
import FileConfigsForm from '../../../forms/FileConfigsForm';

// Import additional Ant Design components
const { Title } = Typography;

const DataSourceConfigs = (props) => {
  // Extract values from props
  const { form, selectedSource, analysedData, loadingAnalysedData, fieldConfigs, setFieldConfigs } =
    props;

  // Extract values from selected source
  const { type: sourceType, target: sourceTarget, integrationType } = selectedSource || {};

  // Extract values from data
  const {
    source: analysedSource,
    sourceType: analysedSourceType,
    fileContents = [],
    summary,
  } = analysedData || {};
  const { numOfEntries, numOfVars } = summary || {};

  // Prepare file contents columns
  const fileContentsCols = Object.keys(fileContents[0] || {}).map((colName) => ({
    title: colName,
    dataIndex: colName,
    ellipsis: { showTitle: false },
    render: (value) => (
      <Tooltip placement="topLeft" title={value}>
        {value}
      </Tooltip>
    ),
  }));

  if (sourceType === 'integration') {
    return (
      <>
        {/* Integration source configurations */}
        <IntegrationConfigsForm form={form} />

        <Divider />

        {/* Review profiles management */}
        {integrationType === 'datashake' && <DatashakeForm form={form} />}

        {/* Reddit */}
        {integrationType === 'reddit' && <RedditForm form={form} />}

        {/* Facebook */}
        {integrationType === 'facebook' && (
          <FacebookForm form={form} integrationId={sourceTarget} />
        )}

        {integrationType === 'genesys' && <GenesysForm form={form} integrationId={sourceTarget} />}

        {integrationType === 'outlookApplication' && <OutlookApplicationForm form={form} />}
      </>
    );
  }

  // Display a spinner on analysing the data
  if (loadingAnalysedData) {
    return (
      <div style={{ textAlign: 'center', padding: '30px 0px' }}>
        <Spin tip="Analysing data ..." />
      </div>
    );
  }

  // Show not supported analysis results
  if (!analysedData) {
    return (
      <Alert
        message="Not Supported"
        description="Selected file or files are not supported for data profiling and configurations."
        type="info"
        showIcon
      />
    );
  }

  return (
    <>
      {/* File source configurations */}
      <FileConfigsForm form={form} fieldConfigs={fieldConfigs} />

      <Divider />

      {/* File contents */}
      <Title level={5}>Sample File Contents</Title>
      <Table
        dataSource={fileContents}
        columns={fileContentsCols}
        pagination={{ pageSize: 20, showSizeChanger: false }}
        rowKey={() => nanoid()}
        size="small"
        bordered
        scroll={{ x: numOfVars * 180, y: 350 }}
      />

      <Divider />

      {/* File summary */}
      <Title level={5}>Sample File Summary</Title>
      <Descriptions column={1} labelStyle={{ fontWeight: 500, minWidth: '300px' }}>
        <Descriptions.Item label="Analyzed source">{analysedSource}</Descriptions.Item>

        <Descriptions.Item label="Analyzed source type">{analysedSourceType}</Descriptions.Item>

        <Descriptions.Item label="Number of lines analyzed">{numOfEntries}</Descriptions.Item>

        <Descriptions.Item label="Number of variables analyzed">{numOfVars}</Descriptions.Item>
      </Descriptions>

      <Divider />

      {/* File data field analysis and configurations */}
      <DataFieldAnalysis
        data={analysedData}
        fieldConfigs={fieldConfigs}
        setFieldConfigs={setFieldConfigs}
      />
    </>
  );
};

export default DataSourceConfigs;
