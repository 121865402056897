/** @jsxImportSource @emotion/react */

// Import libraries
import dayjs from 'dayjs';
import { pick } from 'lodash';
import urlJoin from 'url-join';
import queryString from 'query-string';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useAuth } from '@otso/auth-wrapper';

// Import Ant Design components
import { Card, Modal, Typography, Drawer, Form, Space, Button, Spin, Divider, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

// Import modules
import { postApi, deleteApi } from '../../modules/api';

// Import utilities
import constructDirPath from '../../utilities/filePath';

// Import components
import FileBrowser from '../../components/FileBrowser';

// Import forms
import ShareConfigsForm from './forms/ShareConfigsForm';

// Import stylesheet
import { button as buttonStyles } from '../../styles/presets';
import styles from './styles';

// Import additional Ant Design components
const { confirm, success } = Modal;
const { Title, Paragraph, Link } = Typography;

// Extract values from environment variables
const { REACT_APP_GCS_ORG_BUCKET_PREFIX } = process.env;

const Files = () => {
  // Load data from contexts
  const { user } = useAuth();

  // Obtain the current organisation slug
  const { currentOrg: currentOrgSlug } = user || {};

  // Initialise form
  const [createDatasetForm] = Form.useForm();
  const [shareForm] = Form.useForm();

  // Initialisation
  const history = useHistory();
  const location = useLocation();
  const [selectedSource, setSelectedSource] = useState({});
  const [shareDrawerVisible, setShareDrawerVisible] = useState(false);
  const [loadingShareConfigs, setLoadingShareConfigs] = useState(false);
  const [savingShareConfigs, setSavingShareConfigs] = useState(false);
  const [deletingShareConfigs, setDeletingShareConfigs] = useState(false);
  const [currentShareConfigs, setCurrentShareConfigs] = useState({});
  const [passwordDeletion, setPasswordDeletion] = useState(false);

  // Prepare initial path
  const { search = '' } = location || {};
  const { path } = queryString.parse(search) || {};
  const initDirPath = location.search ? urlJoin('data', path) : 'data';

  // On source selected
  const onSourceSelected = (selectedSources) => {
    if (selectedSources.length > 1) {
      Modal.info({
        title: 'Select a Single Source',
        content: (
          <Paragraph>
            You can only select a single source. The source can be either a folder or a single file.
          </Paragraph>
        ),
      });
      return false;
    }

    setSelectedSource(selectedSources[0]);
    return true;
  };

  // On directory navigated
  const onDirNavigated = (targetDirPath) => {
    const newLocation = { pathname: '/storage/browser' };
    if (targetDirPath !== 'data') {
      newLocation.search = queryString.stringify({
        path: targetDirPath.startsWith('data')
          ? constructDirPath(targetDirPath).slice(1).join('/')
          : targetDirPath,
      });
    }
    history.push(newLocation);
  };

  /** Load share configurations on a folder or a file. */
  const loadShareConfigs = async (selectedSources) => {
    const validSource = onSourceSelected(selectedSources);
    if (validSource) {
      setShareDrawerVisible(true);

      try {
        setLoadingShareConfigs(true);

        // Send a request to load share configurations on the selected source
        const { data } = await postApi('storage/share/search', {
          source: selectedSources[0].fullPath,
          sourceType: selectedSources[0].contentType,
        });
        const { shareConfigs } = data || {};

        if (shareConfigs) setCurrentShareConfigs(shareConfigs);
      } catch (error) {
        message.error('Unable to load share configurations on the selected source');
      } finally {
        setLoadingShareConfigs(false);
      }

      shareForm.resetFields();
    }
  };

  // On finish or cancel editing forms
  const onFinish = () => {
    setSelectedSource({});
    setShareDrawerVisible(false);
    setCurrentShareConfigs({});
    setPasswordDeletion(false);
    createDatasetForm.resetFields();
    shareForm.resetFields();
  };

  // Construct a share link
  const constructShareLink = (id) => urlJoin(process.env.REACT_APP_DATA_CLIENT_URL, 'share', id);

  // Save share configurations
  const saveShareConfigs = async (values) => {
    try {
      setSavingShareConfigs(true);

      // Extract values
      const { expiration, password, ...otherValues } = values;

      // Prepare share configurations data
      const data = {
        ...otherValues,
        ...(expiration ? { expiration: expiration.format() } : {}),
        source: selectedSource.fullPath,
        sourceType: selectedSource.contentType,
        password,
        passwordDeletion,
        passwordProtected:
          (!passwordDeletion && currentShareConfigs.passwordProtected) || password !== undefined,
      };

      // Prepare a share configurations id
      const shareConfigsId = currentShareConfigs.id || nanoid();

      // Send a request to set share configurations and credentials
      await postApi(`storage/share/${shareConfigsId}`, data);

      onFinish();
      success({
        title: 'Share configurations saved',
        content: (
          <>
            <Paragraph>You can copy the url from below.</Paragraph>
            <Link href={constructShareLink(shareConfigsId)} target="_blank" strong copyable>
              {constructShareLink(shareConfigsId)}
            </Link>
          </>
        ),
        width: 600,
      });
    } catch (error) {
      message.error('Unable to save share configurations, please try again later');
    } finally {
      setSavingShareConfigs(false);
    }
  };

  // Delete share configurations
  const deleteShareConfigs = async () => {
    confirm({
      title: 'Deletion Confirmation',
      icon: <ExclamationCircleOutlined />,
      content: 'Do you want to delete the share configurations?',
      onOk: async () => {
        try {
          setDeletingShareConfigs(true);

          // Prepare a share configurations id
          const { id: shareConfigsId } = currentShareConfigs;

          // Send a request to delete share configurations and associated credentials
          await deleteApi(`storage/share/${shareConfigsId}`);

          onFinish();
          message.success('Share configurations deleted');
        } catch (error) {
          message.error('Unable to delete share configurations, please try again later');
        } finally {
          setDeletingShareConfigs(false);
        }
      },
    });
  };

  return (
    <>
      <Card style={styles.filesWrapper}>
        <FileBrowser
          bucket={currentOrgSlug ? REACT_APP_GCS_ORG_BUCKET_PREFIX + currentOrgSlug : null}
          initDirPath={initDirPath}
          onDirNavigated={onDirNavigated}
          allowUpload
          allowCreateFolder
          extraActions={[
            {
              name: 'share',
              label: 'Share',
              onClick: loadShareConfigs,
            },
          ]}
        />
      </Card>

      {/* Drawer for configuring share */}
      <Drawer
        title="Share Configurations"
        width={600}
        onClose={onFinish}
        closable={!savingShareConfigs}
        open={shareDrawerVisible}
        footer={
          <Space>
            <Button
              onClick={onFinish}
              disabled={savingShareConfigs || deletingShareConfigs}
              style={buttonStyles}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => shareForm.submit()}
              loading={savingShareConfigs}
              disabled={deletingShareConfigs}
              style={buttonStyles}
            >
              Save
            </Button>
            {currentShareConfigs.id && (
              <Button
                type="danger"
                onClick={deleteShareConfigs}
                disabled={savingShareConfigs}
                loading={deletingShareConfigs}
                style={buttonStyles}
              >
                Delete
              </Button>
            )}
          </Space>
        }
      >
        {loadingShareConfigs ? (
          <Spin />
        ) : (
          <>
            {/* Existing share link if available */}
            {currentShareConfigs.id && (
              <>
                <Title level={5}>Share Link:</Title>
                <Link
                  href={constructShareLink(currentShareConfigs.id)}
                  target="_blank"
                  strong
                  copyable
                >
                  {constructShareLink(currentShareConfigs.id)}
                </Link>

                <Divider />
              </>
            )}

            {/* Share configurations */}
            <ShareConfigsForm
              form={shareForm}
              initialValues={{
                ...pick(currentShareConfigs, [
                  'permissions',
                  'labels',
                  'expiration',
                  'noteToRecipient',
                ]),
                ...(currentShareConfigs.expiration
                  ? {
                      expiration: dayjs(currentShareConfigs.expiration),
                    }
                  : {}),
              }}
              initPasswordEditEnabled={!currentShareConfigs.passwordProtected}
              uploadEnabled={selectedSource.contentType === 'folder'}
              onFinish={saveShareConfigs}
              onPasswordRemoved={() => setPasswordDeletion(true)}
            />
          </>
        )}
      </Drawer>
    </>
  );
};

export default Files;
