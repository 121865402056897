// Import presets
import { pageContentWrapper } from '../../styles/presets';

// Prepare a stylesheet
const styles = {
    datasetWrapper: {
        ...pageContentWrapper,
        minWidth: '700px',
    },
    versionWrapperBody: {
        padding: '1px 0px 0px',
    },
};

export default styles;
