const globals = {
    '#root': {
        minHeight: '100vh',
    },
    body: {
        margin: 0,
    },
    '.ant-layout': { height: '100%' },
};

export default globals;
