// Import libraries
import { pick } from 'lodash';
import BBPromise from 'bluebird';
import firebase from 'firebase/app';

/**
 * Authenticate and sign in with a custom token.
 * @param {string} token a custom token
 * @returns a promise to asynchronously sign in
 */
export const signInWithCustomToken = async (token) => {
    return firebase.auth().signInWithCustomToken(token);
};

/**
 * Retrieve custom claims of the currently logged in user.
 * @returns an object containing user's custom claims or null if no authenticated user found.
 */
export const getUserCustomClaims = async () => {
    // Retrieve currently logged in user
    const { currentUser } = await firebase.auth();

    // Generate an identity token
    if (currentUser) {
        const { email, photoURL, displayName, uid } = currentUser;
        const token = await currentUser.getIdTokenResult(true);
        return { ...token.claims, email, photoURL, displayName, uid };
    }

    return null;
};

/**
 * List items (files) and prefixes (folders) under a directory specified.
 * @param {string} bucket Name of the bucket
 * @param {string} dirPath Path of the directory to be listed
 * @param {string} pageToken Page token
 * @param {number} maxResults Maximum number of results to be fetched
 * @returns a promise to list all items and prefixes
 */
export const listFiles = async (bucket, dirPath, pageToken, maxResults = 20) => {
    // Initialisation
    const files = [];

    // Initialise a storage instance
    const storage = firebase.app().storage(`gs://${bucket}`);

    // Create a storage reference from the storage instance
    const storageRef = storage.ref();

    const listRef = storageRef.child(dirPath);

    // Find all the prefixes and items
    const options = { maxResults };
    if (pageToken) options.pageToken = pageToken;
    const { prefixes, items, nextPageToken } = (await listRef.list(options)) || {};

    // Iterate through each folder
    prefixes.forEach((folderRef) => {
        files.push({
            contentType: 'folder',
            ...pick(folderRef, ['name', 'fullPath']),
        });
    });

    // Iterate through each file
    await Promise.all(
        items.map(async (itemRef) => {
            const metadata = await itemRef.getMetadata();
            files.push(pick(metadata, ['name', 'size', 'contentType', 'timeCreated', 'fullPath']));
        })
    );

    return { files, nextPageToken };
};

/**
 * Delete items on a GCS bucket.
 * @param {string} bucket Bucket name
 * @param {string[]} itemPaths Path of items to be deleted
 * @returns a promise to delete GCS files
 */
export const deleteFiles = async (bucket, itemPaths) => {
    return BBPromise.map(
        itemPaths,
        async (itemPath) => {
            // Initialise a storage instance
            const storage = firebase.app().storage(`gs://${bucket}`);

            // Create a storage reference from the storage instance
            const storageRef = storage.ref();

            const desertRef = storageRef.child(itemPath);
            await desertRef.delete();
        },
        { concurrency: 3 }
    );
};

/**
 * Upload a file to GCS bucket.
 * @param {string} bucket Bucket name
 * @param {string} itemPath Path of the item to be created
 * @param {Object} file File to be uploaded
 * @returns an object that can be used to monitor and manage the upload
 */
export const uploadFile = (bucket, itemPath, file) => {
    // Initialise a storage instance
    const storage = firebase.app().storage(`gs://${bucket}`);

    // Create a storage reference from the storage instance
    const storageRef = storage.ref();

    const createRef = storageRef.child(itemPath);
    return createRef.put(file);
};

/**
 * Check if a file exists by providing a full path.
 * @param {string} bucket Bucket name
 * @param {string} itemPath Path of the item to be checked
 * @returns true if file exists otherwise false
 */
export const checkFileExists = async (bucket, itemPath) => {
    // Initialise a storage instance
    const storage = firebase.app().storage(`gs://${bucket}`);

    // Create a storage reference from the storage instance
    const storageRef = storage.ref();

    const itemRef = storageRef.child(itemPath);

    try {
        // Check if a file exists by fetching metadata
        await itemRef.getMetadata();
        return true;
    } catch (error) {
        return false;
    }
};

/**
 * Generate a file download url.
 * @param {string} bucket Bucket name
 * @param {string} itemPath Path of the item to be downloaded
 * @returns a promise to resolve a file download link
 */
export const getDownloadFileURL = (bucket, itemPath) => {
    // Initialise a storage instance
    const storage = firebase.app().storage(`gs://${bucket}`);

    // Create a storage reference from the storage instance
    const storageRef = storage.ref();

    const downloadRef = storageRef.child(itemPath);
    return downloadRef.getDownloadURL();
};
