/** @jsxImportSource @emotion/react */

// Import components
import IntegrationsTable from '../../../Integrations/IntegrationsTable';

const DatasetIntegration = (props) => {
    // Extract values from props
    const { selectedSource, onSelectedSourceChange, allowedTypes = [], filteredTypes = [] } = props;

    return (
        <IntegrationsTable
            rowSelection={{
                type: 'radio',
                selectedRowKeys: [selectedSource],
                onChange: (selectedKeys, selectedRows) => {
                    onSelectedSourceChange(
                        selectedKeys[0],
                        selectedRows[0].name,
                        selectedRows[0].type
                    );
                },
            }}
            headerStyles={{ paddingTop: 0 }}
            allowedTypes={allowedTypes}
            filteredTypes={filteredTypes}
        />
    );
};

export default DatasetIntegration;
