/** @jsxImportSource @emotion/react */

// Import libraries
import dayjs from 'dayjs';
import { get } from 'lodash';
import urlJoin from 'url-join';
import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

// Import Ant Design components
import { Layout, Typography, Spin, Result, Button, Card, Alert, Input, message } from 'antd';

// Import modules
import { postApi } from '../../modules/api';

// Import components
import Uploader from './components/Uploader';
import Downloader from './components/Downloader';

// Import stylesheets
import styles from './styles';

// Import additional Ant Design components
const { Text } = Typography;
const { Header, Content, Footer } = Layout;

const Share = () => {
    // Obtain the share id from path parameters
    const { shareId } = useParams();

    // Initialisation
    const [loadingShare, setLoadingShare] = useState(false);
    const [shareConfigs, setShareConfigs] = useState({});
    const [password, setPassword] = useState(null);
    const [passwordRequired, setPasswordRequired] = useState(false);
    const [tabList, setTabList] = useState([]);
    const [currentTab, setCurrentTab] = useState(null);
    const history = useHistory();

    useEffect(() => {
        const getShareById = async () => {
            try {
                setLoadingShare(true);
                setPasswordRequired(false);

                // Obtain share configurations
                const res = await postApi(urlJoin('share', shareId), {
                    password,
                });
                const { shareConfigs: configs } = res.data || {};
                setShareConfigs(configs || {});

                // Prepare operation tabs and current active tab
                const { permissions = [] } = configs || {};
                const operationTabs = [];
                if (permissions.indexOf('upload') >= 0) {
                    operationTabs.push({ key: 'upload', tab: 'Upload' });
                }
                if (permissions.indexOf('download') >= 0) {
                    operationTabs.push({ key: 'download', tab: 'Download' });
                }
                setTabList(operationTabs);
                setCurrentTab(operationTabs.length > 0 ? operationTabs[0].key : null);
            } catch (error) {
                if (get(error, 'response.status') === 401) {
                    setPasswordRequired(true);
                } else {
                    message.error('Unable to obtain the share details');
                }
            }

            setLoadingShare(false);
        };

        if (shareId) getShareById(shareId);
    }, [shareId, password]);

    // Generate a signed upload url
    const generateUploadUrl = async (name) => {
        const res = await postApi(urlJoin('share', shareId, 'upload'), {
            name,
            password,
        });
        const { signedUploadUrl } = res.data || {};

        return signedUploadUrl;
    };

    // Generate a signed download url
    const generateDownloadUrl = async () => {
        const res = await postApi(urlJoin('share', shareId, 'download'), {
            password,
        });
        const { signedDownloadUrl } = res.data || {};

        return signedDownloadUrl;
    };

    return (
        <Layout style={styles.layout}>
            {/* Header */}
            <Header style={styles.header}>
                <img src="/logo/otso_white.png" alt="otso Logo" style={styles.logo} />
            </Header>

            {/* Content */}
            <Content style={styles.content}>
                <Spin size="large" spinning={loadingShare}>
                    {/* Show a friendly error message on invalid share detected */}
                    {!loadingShare && !shareConfigs.source ? (
                        <Result
                            status={passwordRequired ? '403' : '404'}
                            title={
                                passwordRequired
                                    ? 'Password required'
                                    : '"Expired or Invalid Share"'
                            }
                            subTitle={
                                passwordRequired
                                    ? 'This share is password-protected. Please enter password or contact support for help.'
                                    : 'Sorry, the share is either expired or invalid. Please contact support for help.'
                            }
                            extra={
                                passwordRequired ? (
                                    <Input.Search
                                        onSearch={(value) => setPassword(value)}
                                        enterButton="Unlock"
                                        placeholder="Enter password"
                                        style={{ width: '300px' }}
                                    />
                                ) : (
                                    <Button type="primary" onClick={() => history.push('/')}>
                                        otso Data Home
                                    </Button>
                                )
                            }
                        />
                    ) : (
                        <div css={styles.shareWrapper}>
                            {/* Notes to recipient */}
                            {shareConfigs.noteToRecipient && (
                                <Card title="Notes to recipient">
                                    <Alert
                                        message={shareConfigs.noteToRecipient}
                                        type="info"
                                        showIcon
                                    />
                                </Card>
                            )}

                            <Card
                                tabList={tabList}
                                onTabChange={(key) => setCurrentTab(key)}
                                tabBarExtraContent={
                                    shareConfigs.expiration ? (
                                        <Text type="secondary">
                                            {`Expired on ${dayjs(shareConfigs.expiration).format(
                                                'DD/MM/YYYY'
                                            )}`}
                                        </Text>
                                    ) : (
                                        <Text type="secondary">No Expiry</Text>
                                    )
                                }
                            >
                                {/* Upload data */}
                                {currentTab === 'upload' && (
                                    <Uploader generateUploadUrl={generateUploadUrl} />
                                )}

                                {/* Download data */}
                                {currentTab === 'download' && (
                                    <Downloader
                                        generateDownloadUrl={generateDownloadUrl}
                                        shareConfigs={shareConfigs}
                                    />
                                )}
                            </Card>
                        </div>
                    )}
                </Spin>
            </Content>

            {/* Footer */}
            <Footer style={styles.footer}>
                <Text type="secondary">otso Data © 2021 Powered by otso Pty Ltd</Text>
            </Footer>
        </Layout>
    );
};

export default Share;
