/** @jsxImportSource @emotion/react */

// Import libraries
import { useEffect } from 'react';
import cronTime from 'cron-time-generator';

// Import Ant Design components
import { Form, Switch, Select } from 'antd';

// Import additional Ant Design components
const { Option, OptGroup } = Select;

// Prepare streaming frequencies
const specificDaysStreamingFrequencies = [
    {
        name: 'Every Monday at 10am',
        value: cronTime.onSpecificDaysAt(['monday'], 10),
    },
    {
        name: 'Every Monday, Wednesday, Friday at 10am',
        value: cronTime.onSpecificDaysAt(['monday', 'wednesday', 'friday'], 10),
    },
    {
        name: 'Every Tuesday, Thursday at 10am',
        value: cronTime.onSpecificDaysAt(['tuesday', 'thursday'], 10),
    },
];
const otherStreamingFrequencies = [
    { name: 'Every week day at 10am', value: cronTime.everyWeekDayAt(10) },
    { name: 'Every day at 10am', value: cronTime.everyDayAt(10) },
];

const IntegrationConfigsForm = ({ form, initialValues }) => {
    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, [form, initialValues]);

    return (
        <Form
            name="integrationConfigsForm"
            form={form}
            initialValues={initialValues}
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}
        >
            {/* Enable or disable the data streaming */}
            <Form.Item
                name="dataStreamingEnabled"
                label="Enable Data Streaming"
                valuePropName="checked"
            >
                <Switch />
            </Form.Item>

            {/* Data streaming frequency */}
            {/* Note: Review profiles older than 45 days automatically get purged from Datashake servers. */}
            <Form.Item noStyle dependencies={['dataStreamingEnabled']}>
                {({ getFieldValue }) =>
                    getFieldValue('dataStreamingEnabled') ? (
                        <Form.Item
                            name="dataStreamingFrequency"
                            label="Data Streaming Frequency"
                            rules={[
                                {
                                    required: true,
                                    message: 'Data streaming frequency must be specified',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select a frequency"
                                dropdownMatchSelectWidth={false}
                            >
                                <OptGroup label="On Specific Days">
                                    {specificDaysStreamingFrequencies.map((frequency) => (
                                        <Option key={frequency.value} value={frequency.value}>
                                            {frequency.name}
                                        </Option>
                                    ))}
                                </OptGroup>

                                <OptGroup label="Others">
                                    {otherStreamingFrequencies.map((frequency) => (
                                        <Option key={frequency.value} value={frequency.value}>
                                            {frequency.name}
                                        </Option>
                                    ))}
                                </OptGroup>
                            </Select>
                        </Form.Item>
                    ) : null
                }
            </Form.Item>
        </Form>
    );
};

export default IntegrationConfigsForm;
