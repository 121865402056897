/** @jsxImportSource @emotion/react */

// Import Ant Design components
import { Typography, Form, Select, Switch } from 'antd';

// Import additional Ant Design components
const { Title } = Typography;
const { Option } = Select;

const EnrichmentFieldConfigsForm = ({ form, fieldConfigs }) => {
  return (
    <>
      <Title level={5} style={{ marginBottom: '20px' }}>
        Enrichment Field Configurations
      </Title>

      <Form
        name="enrichmentFieldConfigsForm"
        form={form}
        labelAlign="left"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 11 }}
      >
        {/* TODO: Disable switch until verbatim field selected */}
        <Form.Item name="shouldDeidentify" label="Enable De-Identification">
          <Switch checkedChildren="Enabled" unCheckedChildren="Disabled" />
        </Form.Item>

        <Form.Item name="shouldGeolocate" label="Enable Geo-locate addresses">
          <Switch checkedChildren="Enabled" unCheckedChildren="Disabled" />
        </Form.Item>

        <Form.Item name="verbatimFieldName" label="Verbatim Field" required>
          <Select placeholder="Select a verbatim field" showSearch allowClear>
            {Object.values(fieldConfigs)
              .filter(({ type }) => type === 'text')
              .map(({ field, displayName }) => (
                <Option key={field} value={field}>
                  {displayName ? `${field} (${displayName})` : field}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item name="locationFieldName" label="Location Field" required>
          <Select placeholder="Select a location field" showSearch allowClear>
            {Object.values(fieldConfigs)
              .filter(({ type }) => type === 'keyword')
              .map(({ field, displayName }) => (
                <Option key={field} value={field}>
                  {displayName ? `${field} (${displayName})` : field}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    </>
  );
};

export default EnrichmentFieldConfigsForm;
