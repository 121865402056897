/** @jsxImportSource @emotion/react */

// Import libraries
import { useState } from 'react';
import pathParse from 'path-parse';

// Import Ant Design components
import { Card, Button, Avatar, Alert, message } from 'antd';

// Import utilities
import download from '../../../../utilities/downloader';

const Downloader = ({ generateDownloadUrl, shareConfigs }) => {
    // Initialisation
    const [downloading, setDownloading] = useState(false);

    // Evaluate the source path
    const source = pathParse(shareConfigs.source);

    // Download the shared resource
    const triggerDownload = async () => {
        try {
            setDownloading(true);

            // Generate a signed file download link
            const signedDownloadUrl = await generateDownloadUrl();

            // Trigger the file download
            await download(
                signedDownloadUrl,
                shareConfigs.sourceType === 'folder' ? `${source.base}.zip` : source.base
            );
        } catch (error) {
            message.error('Unable to download the resources, please contact support for help');
        }

        setDownloading(false);
    };

    return (
        <>
            {/* Tip for a folder resource */}
            {shareConfigs.sourceType === 'folder' && (
                <Alert
                    message="Please note downloading a folder resource may take some time."
                    type="info"
                    showIcon
                    style={{ marginBottom: '15px' }}
                />
            )}

            {/* Resource details */}
            <Card title="Shared Resources" type="inner">
                <Card.Meta
                    avatar={
                        <Avatar
                            src={
                                shareConfigs.sourceType === 'folder'
                                    ? '/icons/folder.png'
                                    : '/icons/file.png'
                            }
                        />
                    }
                    title={source.base}
                    description={`Type: ${shareConfigs.sourceType}`}
                />
            </Card>

            {/* Trigger for downloading */}
            <Button loading={downloading} type="primary" onClick={triggerDownload}>
                Download
            </Button>
        </>
    );
};

export default Downloader;
