/** @jsxImportSource @emotion/react */

// Import Ant Design components
import { Upload, Badge } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

// Import modules
import { uploadApi } from '../../../../modules/api';

// Import stylesheet
import styles from './styles';

// Import additional Ant Design components
const { Dragger } = Upload;

const Uploader = ({ generateUploadUrl }) => {
    return (
        <>
            {/* Section for click or drag file to upload */}
            <Dragger
                name="file"
                multiple
                disabled={!generateUploadUrl}
                customRequest={async ({
                    file,
                    onProgress,
                    onSuccess,
                    onError,
                }) => {
                    try {
                        // Generate a presigned upload url
                        const uploadUrl = await generateUploadUrl(file.name);

                        // Start uploading file
                        await uploadApi(
                            uploadUrl,
                            file,
                            {},
                            ({ loaded, total }) => {
                                const event = {
                                    percent: parseInt(
                                        (loaded / total) * 100,
                                        10
                                    ),
                                };
                                onProgress(event);
                            }
                        );

                        // On file successfully uploaded
                        onSuccess();
                    } catch (error) {
                        // On error occurred during file upload
                        onError(error);
                    }
                }}
                progress={{
                    strokeColor: {
                        '0%': '#108ee9',
                        '100%': '#87d068',
                    },
                    strokeWidth: 3,
                    format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
                }}
                showUploadList={{
                    showRemoveIcon: false,
                }}
                itemRender={(originNode, file) => {
                    if (file.status === 'uploading') return originNode;
                    return (
                        <div style={styles.uploadListItem}>
                            <div style={styles.uploadListItemInfo}>
                                {originNode}
                            </div>
                            {file.status === 'done' && (
                                <Badge status="success" text="Success" />
                            )}
                            {file.status === 'error' && (
                                <Badge status="error" text="Error" />
                            )}
                        </div>
                    );
                }}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                    Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                    Support for a single or bulk upload.
                </p>
            </Dragger>
        </>
    );
};

export default Uploader;
