// Import libraries
import axios from 'axios';
import fileDownload from 'js-file-download';

/**
 * Download a file via a download url.
 * @param {string} url Download url
 * @param {string} filename Filename
 * @returns a promise to trigger a file download from url
 */
const download = async (url, filename) => {
    const res = await axios.get(url, { responseType: 'blob' });
    return fileDownload(res.data, filename);
};

export default download;
