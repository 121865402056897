/** @jsxImportSource @emotion/react */

// Import libraries
import { useState, useEffect } from 'react';

// Import Ant Design components
import { Form, Select, Input, Space, Tooltip, Switch } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

// Import modules
import { getApi } from '../../../modules/api';

// Import utilities
import isBoolQueryValid from '../../../utilities/boolQueryValidator';

// Import additional Ant Design components
const { Option } = Select;
const { TextArea } = Input;

const RedditForm = ({ form, initialValues }) => {
    // Initialisation
    const [subreddits, setSubreddits] = useState([]);

    useEffect(() => {
        // Obtain supported subreddits
        const getSubreddits = async () => {
            const { data } = await getApi('reddit/subreddits');
            setSubreddits(data.subreddits);
        };
        getSubreddits();
    }, []);

    return (
        <Form name="redditForm" form={form} initialValues={initialValues} layout="vertical">
            {/* Subreddits */}
            <Form.Item name="subreddits" label="Subreddits" rules={[{ required: true }]}>
                <Select
                    mode="multiple"
                    allowClear
                    placeholder="Select subreddits (e.g., r/brisbane)"
                >
                    {subreddits.map((subreddit) => (
                        <Option key={subreddit} value={subreddit}>
                            {subreddit}
                        </Option>
                    ))}
                </Select>
            </Form.Item>

            {/* Reddit Search Query */}
            <Form.Item
                name="redditSearch"
                label={
                    <Space>
                        <span>Reddit Search</span>
                        <Tooltip
                            title={
                                'Reddit searches support the use of parentheses to nest and uppercase boolean operators (AND, OR, NOT). \
                                    Use of quotation marks on multi-word phrases ("for example") for search consistency is strongly advised.'
                            }
                        >
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </Space>
                }
                rules={[
                    {
                        validator: (_, value) => {
                            if (!value || isBoolQueryValid(value)) return Promise.resolve();
                            return Promise.reject(new Error('Boolean query is invalid.'));
                        },
                    },
                ]}
            >
                <TextArea
                    rows={4}
                    placeholder={
                        'Enter boolean search query\nExample: (pineapple AND ham AND pizza) OR "hawaiian pizza"'
                    }
                />
            </Form.Item>

            {/* Enable or disable searches on comments */}
            <Form.Item
                name="searchOnComments"
                label={
                    <Space>
                        <span>Apply Search Filter to Post Comments</span>
                        <Tooltip
                            title={
                                'If this toggle is applied, this will require comments to match the search. \
                                This will narrow the comments presented, and is best used in scenarios where relevancy of commenters is questionable. \
                                If this toggle is not applied, all comments will be shown for any posts that match the search query. \
                                This may result in a large amount of comments, some of which may be off-topic or seem irrelevant to the search.'
                            }
                        >
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </Space>
                }
                initialValue={false}
                valuePropName="checked"
            >
                <Switch />
            </Form.Item>

            {/* Flair */}
            <Form.Item name="flair" label="Post Flair">
                <Input placeholder="Enter flair text (optional)" />
            </Form.Item>
        </Form>
    );
};

export default RedditForm;
