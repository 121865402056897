/** @jsxImportSource @emotion/react */

// Import libraries
import urlJoin from 'url-join';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider, useMsal } from '@azure/msal-react';

// Import Ant Design components
import { Button, Alert, message } from 'antd';
import { WindowsOutlined } from '@ant-design/icons';

// Prepare stylesheet
import { button as buttonStyles } from '../../../styles/presets';

// Initialise a public client application
const msalInstance = new PublicClientApplication({
    auth: {
        clientId: process.env.REACT_APP_MICROSOFT_APP_CLIENT_ID,
        redirectUri: urlJoin(process.env.REACT_APP_DATA_CLIENT_URL || '', 'integrations/create'),
    },
});

// Prepare application scopes
const scopes = ['Mail.Read', 'User.Read'];

const OutlookContainer = ({ disabled, authData, setAuthData }) => {
    // Extract values from context
    const { instance, accounts = [] } = useMsal();

    // On connecting with the Microsoft Outlook
    const onLogin = async () => {
        // Initialisation
        let authRes = null;

        try {
            // If no account has been set up locally, use the pop-up approach instead
            if (accounts.length === 0) throw new Error('No account has been set up locally');

            // Attempt to acquire token silently
            authRes = await instance.acquireTokenSilent({
                scopes,
                account: accounts[0],
            });
        } catch (error) {
            // If failed, pop up an auth window to acquire a token
            try {
                authRes = await msalInstance.acquireTokenPopup({ scopes });
            } catch (e) {
                // Setup failed
                message.warning('Outlook integration setup failed, please try again later');
            }
        }

        // Obtain the id token and update the auth data
        if (authRes) {
            // Update the integration authentication data
            const { idToken, idTokenClaims } = authRes || {};
            const { name: displayName, preferred_username: userPrincipalName } = idTokenClaims;
            setAuthData({
                idToken,
                displayName,
                userPrincipalName,
                type: 'outlook',
            });
        }
    };

    // On resetting the credentials
    const onReset = async () => {
        if (accounts.length > 0) {
            await msalInstance.logoutPopup({ account: accounts[0] });
        }
        setAuthData(null);
    };

    return (
        <div style={{ margin: '30px 0px' }}>
            {authData ? (
                <Alert
                    message={`Connected with ${authData.displayName || '-'} (${
                        authData.userPrincipalName || '-'
                    })`}
                    type="success"
                    showIcon
                    action={
                        !disabled ? (
                            <Button size="small" type="text" onClick={onReset}>
                                Clear
                            </Button>
                        ) : null
                    }
                    style={{ margin: '10px 0px 30px' }}
                />
            ) : (
                <Button
                    type="primary"
                    size="large"
                    onClick={onLogin}
                    disabled={disabled}
                    icon={<WindowsOutlined />}
                    style={buttonStyles}
                >
                    Connect with Outlook
                </Button>
            )}
        </div>
    );
};

const OutlookAuthForm = (props) => (
    <MsalProvider instance={msalInstance}>
        <OutlookContainer {...props} />
    </MsalProvider>
);

export default OutlookAuthForm;
