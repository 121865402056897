/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';

import { Button, Space, Table, Tooltip, Input, Empty, message } from 'antd';
import { ReloadOutlined, SearchOutlined } from '@ant-design/icons';

import { getApi } from '../../../../modules/api';

import styles from './styles';

const VertexEndpoints = (props) => {
  const { vertexEndpoints, setVertexEndpoints } = props;

  const [endpoints, setEndpoints] = useState([]);
  const [loadingEndpoints, setLoadingEndpoints] = useState(false);
  const [refreshedAt, setRefreshedAt] = useState(new Date().getTime());
  const [searchValue, setSearchValue] = useState(null);

  useEffect(() => {
    const getOrgIntegrations = async () => {
      try {
        setLoadingEndpoints(true);

        const orgIntegrations = [];

        const { data = [] } = await getApi('vertex');

        data.forEach((integration) => {
          orgIntegrations.push(integration);
        });

        setEndpoints(orgIntegrations);
      } catch (error) {
        message.error('Unable to retrieve integrations, please try again later');
      } finally {
        setLoadingEndpoints(false);
      }
    };

    getOrgIntegrations();
  }, [refreshedAt]);

  const refresh = () => setRefreshedAt(new Date().getTime());

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      responsive: ['lg'],
    },
  ];

  return (
    <>
      <div style={{ ...styles.header }}>
        <Space>
          <Input
            placeholder="Search integration"
            prefix={<SearchOutlined />}
            bordered={false}
            onChange={(e) => setSearchValue(e.target.value)}
            allowClear
          />
          <Tooltip title="Refresh">
            <Button type="text" icon={<ReloadOutlined />} onClick={refresh} />
          </Tooltip>
        </Space>
      </div>

      <Table
        dataSource={
          searchValue ? endpoints.filter((item) => item.name.indexOf(searchValue) >= 0) : endpoints
        }
        rowKey="id"
        loading={loadingEndpoints}
        columns={columns}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: vertexEndpoints,
          onChange: (selectedKeys) => {
            setVertexEndpoints(selectedKeys);
          },
        }}
        locale={{
          emptyText: <Empty description="No integrations found" style={styles.empty} />,
        }}
      />
    </>
  );
};

export default VertexEndpoints;
