/** @jsxImportSource @emotion/react */

// Import Ant Design components
import { List, Typography } from 'antd';

// Import additional Ant Design components
const { Paragraph } = Typography;

const JSONPreview = (props) => {
    // Extract values from props
    const { data } = props;

    return (
        <List
            bordered
            dataSource={data}
            renderItem={(item) => (
                <List.Item>
                    <Paragraph ellipsis>{JSON.stringify(item)}</Paragraph>
                </List.Item>
            )}
        />
    );
};

export default JSONPreview;
