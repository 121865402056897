/** @jsxImportSource @emotion/react */

// Import libraries
import dayjs from 'dayjs';
import { useState } from 'react';

// Import Ant Design components
import { Form, Input, Checkbox, Select, DatePicker, Empty, Button, Space, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

// Import additional Ant Design components
const { confirm } = Modal;

const ShareConfigsForm = (props) => {
    // Extract values from props
    const {
        form,
        onFinish,
        onPasswordRemoved,
        initialValues = {},
        initPasswordEditEnabled = true,
        uploadEnabled = false,
    } = props;

    // Initialisation
    const [passwordRequired, setPasswordRequired] = useState(false);
    const [passwordEditEnabled, setPasswordEditEnabled] = useState(initPasswordEditEnabled);

    // Prepare permission options
    const permissionOptions = [{ label: 'Allow Download', value: 'download' }];
    if (uploadEnabled) permissionOptions.push({ label: 'Allow Upload', value: 'upload' });

    return (
        <Form form={form} onFinish={onFinish} layout="vertical" initialValues={initialValues}>
            {/* Share permissions */}
            <Form.Item name="permissions" label="Permissions" rules={[{ required: true }]}>
                <Checkbox.Group options={permissionOptions} />
            </Form.Item>

            {/* Share expiration date */}
            <Form.Item name="expiration" label="Expiration Date">
                <DatePicker
                    placeholder="Set expiration date (optional)"
                    disabledDate={(current) => current < dayjs().startOf('day')}
                    style={{ width: '100%' }}
                />
            </Form.Item>

            {/* Share password */}
            <Form.Item name="password" label="Password" rules={[{ required: passwordRequired }]}>
                <Input.Password
                    placeholder={
                        !passwordEditEnabled ? '*******' : 'Enter a share password (optional)'
                    }
                    disabled={!passwordEditEnabled}
                />
            </Form.Item>
            {!passwordEditEnabled && (
                <Space style={{ marginBottom: '24px' }}>
                    <Button
                        type="primary"
                        onClick={() => {
                            setPasswordRequired(true);
                            setPasswordEditEnabled(true);
                        }}
                    >
                        Create New Password
                    </Button>
                    <Button
                        type="danger"
                        onClick={() => {
                            confirm({
                                title: 'Password Deletion',
                                icon: <ExclamationCircleOutlined />,
                                content:
                                    'This action cannot be undone. Are you sure you want to delete this password?',
                                onOk: async () => {
                                    onPasswordRemoved();
                                    setPasswordEditEnabled(true);
                                },
                            });
                        }}
                    >
                        Remove
                    </Button>
                </Space>
            )}

            {/* Share label */}
            <Form.Item name="labels" label="Labels">
                <Select
                    mode="tags"
                    placeholder="Enter share labels (optional)"
                    allowClear
                    notFoundContent={
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="Create first share label"
                        />
                    }
                />
            </Form.Item>

            {/* Share notes */}
            <Form.Item name="noteToRecipient" label="Note to recipient">
                <Input.TextArea rows={3} placeholder="Enter a note to recipient (optional)" />
            </Form.Item>
        </Form>
    );
};

export default ShareConfigsForm;
