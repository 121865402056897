/** @jsxImportSource @emotion/react */

// Import Ant Design components
import { Form, Select, Typography } from 'antd';

// Import additional Ant Design components
const { Title } = Typography;
const { Option } = Select;

const FileConfigsForm = (props) => {
    // Extract values from props
    const { form, fieldConfigs } = props;

    return (
        <>
            <Title level={5} style={{ marginBottom: '20px' }}>
                File Source Configurations
            </Title>

            <Form
                name="fileSourceConfigsForm"
                form={form}
                labelAlign="left"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 11 }}
            >
                <Form.Item name="idFieldName" label="Document ID Field">
                    <Select
                        placeholder="Select a document id field (optional)"
                        showSearch
                        allowClear
                    >
                        {Object.values(fieldConfigs).map(({ field, displayName }) => (
                            <Option key={field} value={field}>
                                {displayName ? `${field} (${displayName})` : field}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item name="dateFieldName" label="Document Time Field">
                    <Select
                        placeholder="Select a document time field (optional)"
                        showSearch
                        allowClear
                    >
                        {Object.values(fieldConfigs)
                            .filter(({ type }) => type === 'date')
                            .map(({ field, displayName }) => (
                                <Option key={field} value={field}>
                                    {displayName ? `${field} (${displayName})` : field}
                                </Option>
                            ))}
                    </Select>
                </Form.Item>
            </Form>
        </>
    );
};

export default FileConfigsForm;
