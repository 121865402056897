/** @jsxImportSource @emotion/react */

// Import Ant Design components
import { Form, Input } from 'antd';

const OutlookApplicationForm = ({ disabled = false }) => {
    return (
        <>
            <Form.Item name="clientId" label="Client ID" rules={[{ required: true }]}>
                <Input placeholder="Enter Microsoft Application/Client ID" disabled={disabled} />
            </Form.Item>
            <Form.Item name="clientSecret" label="Client Secret" rules={[{ required: true }]}>
                <Input placeholder="Enter Microsoft Client Secret" disabled={disabled} />
            </Form.Item>
            <Form.Item name="tenantId" label="Tenant ID" rules={[{ required: true }]}>
                <Input placeholder="Enter Microsoft Tenant ID" disabled={disabled} />
            </Form.Item>
        </>
    );
};

export default OutlookApplicationForm;
