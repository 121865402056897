/** @jsxImportSource @emotion/react */

// Import Ant Design components
import { Form, Input } from 'antd';

const DatashakeForm = ({ disabled = false }) => {
    return (
        <>
            {/* Datashake API key */}
            <Form.Item name="apiKey" label="API Key" rules={[{ required: true }]}>
                <Input placeholder="Enter Datashake api key" disabled={disabled} />
            </Form.Item>
        </>
    );
};

export default DatashakeForm;
