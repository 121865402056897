/** @jsxImportSource @emotion/react */

// Import Ant Design components
import { Form, Input } from 'antd';

const BangTheTableForm = ({ disabled = false }) => {
  return (
    <>
      <Form.Item name="clientAPIEndpoint" label="URL" rules={[{ required: true }]}>
        <Input placeholder="Enter Client Endpoint" disabled={disabled} />
      </Form.Item>
      <Form.Item name="clientUsername" label="Username" rules={[{ required: true }]}>
        <Input placeholder="Enter Client Username" disabled={disabled} />
      </Form.Item>
      <Form.Item name="clientPassword" label="Password" rules={[{ required: true }]}>
        <Input placeholder="Enter Client Password" disabled={disabled} />
      </Form.Item>
    </>
  );
};

export default BangTheTableForm;
