/** @jsxImportSource @emotion/react */

// Import libraries
import axios from 'axios';
import { pick } from 'lodash';
import { useState, useEffect } from 'react';

// Import Ant Design components
import { Button, List } from 'antd';
import { FacebookOutlined } from '@ant-design/icons';

// Prepare stylesheet
import { button as buttonStyles } from '../../../styles/presets';

const FacebookAuthForm = ({ disabled, authData, setAuthData }) => {
    // Initialisation
    const [fbUser, setFBUser] = useState({});
    const [fbPages, setFBPages] = useState([]);

    // Extract facebook pages and user from the authentication data
    const { fbUser: existingUser, fbPages: existingPages } = authData || {};

    useEffect(() => {
        setFBUser(existingUser || {});
        setFBPages(existingPages || []);
        return () => {
            setFBUser({});
            setFBPages([]);
        };
    }, [existingPages, existingUser]);

    const getUser = async (userID, accessToken) => {
        const { data } = await axios.get(`https://graph.facebook.com/${userID}`, {
            params: { access_token: accessToken },
        });
        setFBUser(data || {});
    };

    const getPageAccessList = async (userID, accessToken) => {
        const { data } = await axios.get(`https://graph.facebook.com/${userID}/accounts`, {
            params: { access_token: accessToken },
        });
        setFBPages(data.data || []);
    };

    // On connecting with the Facebook
    const onConnect = async () => {
        window.FB.login(
            ({ authResponse }) => {
                if (authResponse) {
                    // Extract values from authentication response
                    const authentication = pick(authResponse, ['userID', 'accessToken']);

                    // Update authentication data
                    setAuthData(authentication);

                    // Extract values from authentication
                    const { userID, accessToken } = authentication;

                    // Retrieve Facebook user details and its page access list
                    getUser(userID, accessToken);
                    getPageAccessList(userID, accessToken);
                }
            },
            {
                scope: ['pages_read_user_content', 'pages_read_engagement'],
                return_scopes: true,
                enable_profile_selector: true,
            }
        );
    };

    return (
        <List
            locale={{
                emptyText: (
                    <Button
                        type="primary"
                        size="large"
                        onClick={onConnect}
                        disabled={disabled}
                        icon={<FacebookOutlined />}
                        style={buttonStyles}
                    >
                        Connect with Facebook
                    </Button>
                ),
            }}
            header={
                fbUser.name
                    ? `Pages Authorised by ${fbUser.name}`
                    : 'Click below to authorise access. We never post to Facebook.'
            }
            dataSource={fbPages}
            renderItem={(item) => (
                <List.Item>
                    <List.Item.Meta
                        title={`Page Name: ${item.name}`}
                        description={`Page ID: ${item.id}`}
                    />
                </List.Item>
            )}
            style={{ margin: '30px 0px' }}
        />
    );
};

export default FacebookAuthForm;
