/** @jsxImportSource @emotion/react */

// Import libraries
import { useState } from 'react';
import { get } from 'lodash';

// Import Ant Design components
import { Descriptions, Typography, Table, Space, Select, Input, Button } from 'antd';
import {
    UpOutlined,
    DownOutlined,
    NumberOutlined,
    FieldStringOutlined,
    FileWordOutlined,
    CalendarOutlined,
    EditOutlined,
    SaveOutlined,
    CloseOutlined,
    FieldBinaryOutlined,
} from '@ant-design/icons';
import { Column, Bar } from '@ant-design/charts';

// Import additional Ant Design components
const { Title, Text, Paragraph } = Typography;
const { Option } = Select;

// Prepare constants
const SUPPORTED_FIELD_TYPES = {
    float: {
        icon: <FieldBinaryOutlined />,
        alternatives: ['float', 'integer', 'date', 'keyword', 'text'],
    },
    integer: {
        icon: <NumberOutlined />,
        alternatives: ['float', 'integer', 'date', 'keyword', 'text'],
    },
    number: { icon: <NumberOutlined />, alternatives: ['number', 'date', 'keyword', 'text'] },
    date: { icon: <CalendarOutlined />, alternatives: ['date', 'keyword', 'text'] },
    keyword: { icon: <FieldStringOutlined />, alternatives: ['keyword', 'text'] },
    text: { icon: <FileWordOutlined />, alternatives: ['keyword', 'text'] },
};

const DataFieldAnalysis = (props) => {
    // Extract values from props
    const { data, fieldConfigs, setFieldConfigs } = props;

    // Extract values from data
    const { fileStats = [], summary } = data || {};
    const { numOfEntries } = summary || {};

    // Initialisation
    const [fieldDisplayNameInEdit, setFieldDisplayNameInEdit] = useState(null);

    // Prepare columns
    const fileStatsCols = [
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: '15%',
            render: (initType, { id }) => {
                const { type } = fieldConfigs[id] || {};
                return (
                    <Select
                        value={type}
                        onChange={(value) =>
                            setFieldConfigs((configs) => ({
                                ...configs,
                                [id]: { ...(fieldConfigs[id] || {}), type: value },
                            }))
                        }
                        style={{ width: '100%' }}
                    >
                        {SUPPORTED_FIELD_TYPES[initType].alternatives.map((alternativeType) => (
                            <Option
                                key={alternativeType}
                                label={alternativeType}
                                value={alternativeType}
                            >
                                <Space size="small">
                                    {SUPPORTED_FIELD_TYPES[alternativeType].icon}
                                    <Text>{alternativeType}</Text>
                                </Space>
                            </Option>
                        ))}
                    </Select>
                );
            },
        },
        {
            title: 'Name',
            dataIndex: 'field',
            key: 'field',
            render: (name, { id }) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: ' center',
                            width: '100%',
                        }}
                    >
                        <Text strong>{name}</Text>
                        {fieldDisplayNameInEdit && fieldDisplayNameInEdit.id === id ? (
                            <Space size="small">
                                <Input
                                    value={fieldDisplayNameInEdit.displayName}
                                    onChange={(e) => {
                                        setFieldDisplayNameInEdit({
                                            id,
                                            displayName: e.target.value,
                                        });
                                    }}
                                    placeholder="Enter field display name"
                                />
                                <Button
                                    onClick={() => {
                                        setFieldConfigs((configs) => ({
                                            ...configs,
                                            [id]: {
                                                ...(fieldConfigs[id] || {}),
                                                displayName: fieldDisplayNameInEdit.displayName,
                                            },
                                        }));
                                        setFieldDisplayNameInEdit(null);
                                    }}
                                    icon={<SaveOutlined />}
                                    type="link"
                                    size="small"
                                />
                                <Button
                                    onClick={() => setFieldDisplayNameInEdit(null)}
                                    icon={<CloseOutlined />}
                                    type="text"
                                    size="small"
                                />
                            </Space>
                        ) : (
                            <Space size="small">
                                <Text type="secondary">
                                    {get(fieldConfigs, `[${id}].displayName`)}
                                </Text>
                                <Button
                                    onClick={() =>
                                        setFieldDisplayNameInEdit({
                                            id,
                                            displayName: get(fieldConfigs, `[${id}].displayName`),
                                        })
                                    }
                                    icon={<EditOutlined />}
                                    type="link"
                                    size="small"
                                />
                            </Space>
                        )}
                    </div>
                );
            },
        },
        {
            title: 'Documents (%)',
            dataIndex: 'count',
            key: 'count',
            width: '15%',
            render: (value) => (
                <Space>
                    <Text strong>{value}</Text>
                    <Text>({(value / numOfEntries) * 100}%)</Text>
                </Space>
            ),
        },
        { title: 'Distinct Values', dataIndex: 'distinct', key: 'distinct', width: '15%' },
        {
            title: 'Distributions',
            dataIndex: 'unique',
            key: 'unique',
            width: '25%',
            render: (values, fieldStats) => {
                if (['number', 'float', 'integer'].includes(fieldStats.type)) {
                    return (
                        <Descriptions
                            size="small"
                            layout="vertical"
                            labelStyle={{ fontWeight: 500 }}
                        >
                            <Descriptions.Item label="min">{fieldStats.min}</Descriptions.Item>
                            <Descriptions.Item label="median">
                                {fieldStats.median}
                            </Descriptions.Item>
                            <Descriptions.Item label="max">{fieldStats.max}</Descriptions.Item>
                        </Descriptions>
                    );
                }
                if (fieldStats.type !== 'text' && fieldStats.valid > 0) {
                    const config = {
                        data: values,
                        height: 80,
                        maxColumnWidth: 15,
                        minColumnWidth: 15,
                        xField: 'value',
                        yField: 'count',
                        xAxis: {
                            title: {
                                text: `Top ${values.length} of ${fieldStats.distinct} categories`,
                            },
                            label: '',
                            grid: {
                                line: {
                                    style: { lineWidth: 0 },
                                },
                            },
                        },
                        yAxis: {
                            label: '',
                            grid: {
                                line: {
                                    style: { lineWidth: 0 },
                                },
                            },
                        },
                    };
                    return <Column {...config} />;
                }
                return null;
            },
        },
    ];

    return (
        <>
            {/* File statistics */}
            <Title level={5}>Sample File Stats</Title>
            <Table
                dataSource={fileStats}
                columns={fileStatsCols}
                pagination={false}
                rowKey="id"
                size="small"
                expandable={{
                    expandedRowRender: (record) => {
                        if (record.type === 'text') {
                            return (
                                <>
                                    <Paragraph strong>EXAMPLES</Paragraph>
                                    {record.unique.map(
                                        ({ value, id }) =>
                                            value !== 'null' && (
                                                <Paragraph key={id}>{value}</Paragraph>
                                            )
                                    )}
                                </>
                            );
                        }
                        return (
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '25%', marginRight: '10%' }}>
                                    <Paragraph strong>DOCUMENTS STATS</Paragraph>
                                    <Descriptions column={1} size="small">
                                        <Descriptions.Item label="count">
                                            {record.count}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="percentage">
                                            {(record.count / numOfEntries) * 100}%
                                        </Descriptions.Item>
                                        <Descriptions.Item label="distinct values">
                                            {record.distinct}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </div>

                                {['number', 'float', 'integer'].includes(record.type) && (
                                    <div style={{ width: '25%', marginRight: '10%' }}>
                                        <Paragraph strong>SUMMARY</Paragraph>
                                        <Descriptions column={1} size="small">
                                            <Descriptions.Item label="min">
                                                {record.min}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="median">
                                                {record.median}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="max">
                                                {record.max}
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </div>
                                )}

                                <div style={{ width: '25%' }}>
                                    <Paragraph strong>TOP VALUES</Paragraph>
                                    <Bar
                                        data={record.unique.map((values) => ({
                                            ...values,
                                            percentage: values.count / record.count,
                                        }))}
                                        xField="percentage"
                                        yField="value"
                                        height={20 * record.unique.length}
                                        xAxis={{
                                            label: '',
                                            grid: {
                                                line: {
                                                    style: { lineWidth: 0 },
                                                },
                                            },
                                        }}
                                        yAxis={{
                                            label: { autoEllipsis: true, autoRotate: false },
                                        }}
                                        label={{
                                            position: 'middle',
                                            style: {
                                                fill: '#FFFFFF',
                                                opacity: 0.6,
                                            },
                                            formatter: (values) =>
                                                `${(values.percentage * 100).toFixed(0)}%`,
                                        }}
                                        minBarWidth={15}
                                        maxBarWidth={15}
                                    />
                                </div>
                            </div>
                        );
                    },
                    expandIcon: ({ expanded, onExpand, record }) =>
                        expanded ? (
                            <UpOutlined size="small" onClick={(e) => onExpand(record, e)} />
                        ) : (
                            <DownOutlined size="small" onClick={(e) => onExpand(record, e)} />
                        ),
                }}
            />
        </>
    );
};

export default DataFieldAnalysis;
