/** @jsxImportSource @emotion/react */

// Import libraries
import urljoin from 'url-join';
import { useState, useEffect } from 'react';

// Import Ant Design components
import { Button, Form, Space, Divider, Drawer, Spin, message } from 'antd';

// Import modules
import { postApi, getApi, putApi, deleteApi } from '../../../modules/api';

// Import forms
import FacebookAuthForm from '../forms/FacebookAuthForm';
import OutlookAuthentication from '../forms/OutlookAuthForm';
import IntegrationDetailsForm from '../forms/IntegrationDetailsForm';
import OtsoDeployForm from '../forms/OtsoDeployForm';
import DatashakeForm from '../forms/DatashakeForm';
import GenesysForm from '../forms/GenesysForm';
import OutlookApplicationForm from '../forms/OutlookApplicationForm';
import BangTheTableForm from '../forms/BangTheTableForm';
import BrollyForm from '../forms/BrollyForm';
import EPathwayForm from '../forms/EPathwayForm';

// Import stylesheets
import { button as buttonStyles } from '../../../styles/presets';

const Integration = ({ integrationId, visible, onClose, refreshIntegrations }) => {
  // Initialisation
  const [customAuthData, setCustomAuthData] = useState(null);
  const [savingIntegration, setSavingIntegration] = useState(false);
  const [loadingIntegration, setLoadingIntegration] = useState(false);
  const [deletingIntegration, setDeletingIntegration] = useState(false);
  const [authDisabled, setAuthDisabled] = useState(false);

  // Initialise form
  const [form] = Form.useForm();

  // On integration creation finished or cancelled
  const onReset = () => {
    form.resetFields();
    setCustomAuthData(null);
  };

  // On integration modal closed
  const onDrawerClose = () => {
    onReset();
    onClose();
    setAuthDisabled(false);
  };

  useEffect(() => {
    const fetchIntegration = async () => {
      try {
        setLoadingIntegration(true);
        setAuthDisabled(true);

        // Send a request to obtain details of an integration
        const { data } = await getApi(`integrations/${integrationId}`);

        // Extract value from returned response
        const { createdAt, createdBy, ...values } = data || {};

        // Set form values
        form.setFieldsValue(values);

        // Update custom auth data for some integrations
        if (['outlook', 'facebook'].indexOf(data.type) >= 0 && data.metadata)
          setCustomAuthData(data.metadata);
      } catch (error) {
        message.error('Unable to fetch integration details, please try again later');
      }

      setLoadingIntegration(false);
    };

    if (integrationId) fetchIntegration();
  }, [integrationId, form]);

  // On editing the integration auth configurations
  const onEditIntegrationAuth = () => {
    // Obtain current form values
    const values = form.getFieldsValue();

    // Set all auth config field values to undefined
    const { name, type, description, ...authConfigs } = values || {};
    form.setFieldsValue(
      Object.keys(authConfigs).reduce((prev, key) => {
        return { ...prev, [key]: undefined };
      }, {})
    );

    // Allow editing the auth configs
    setAuthDisabled(false);

    // Clear custom auth data
    setCustomAuthData(null);
  };

  // On integration instructed to be created or saved
  const onSave = async (values) => {
    // Perform extra form validation for specific integration types
    if (['outlook'].indexOf(values.type) >= 0 && !customAuthData) {
      message.warning('Missing integration authentication configurations');
    } else {
      try {
        setSavingIntegration(true);

        // Prepare request payload
        const payload = { ...values, ...customAuthData };

        if (integrationId) {
          await putApi(urljoin('integrations', integrationId), payload);
        } else {
          // Send a request to create a new integration
          await postApi('integrations', payload);
        }

        message.success('Integration saved');
        onDrawerClose();
        refreshIntegrations();
      } catch (error) {
        message.error('Unable to save integration, please try again later');
      } finally {
        setSavingIntegration(false);
      }
    }
  };

  // On integration instructed to be deleted
  const onDelete = async () => {
    try {
      setDeletingIntegration(true);
      await deleteApi(urljoin('integrations', integrationId));

      message.success('Integration deleted');
      onDrawerClose();
      refreshIntegrations();
    } catch (error) {
      message.error('Unable to delete integration, please try again later');
    } finally {
      setDeletingIntegration(false);
    }
  };

  return (
    <Form
      form={form}
      onFinish={onSave}
      layout="vertical"
      labelAlign="left"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
    >
      <Drawer
        title={integrationId ? 'Edit Integration' : 'Create Integration'}
        placement="right"
        width="512px"
        open={visible}
        onClose={onDrawerClose}
        footer={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Space>
              <Button
                type="primary"
                onClick={() => form.submit()}
                loading={savingIntegration}
                disabled={deletingIntegration || loadingIntegration}
                style={buttonStyles}
              >
                {integrationId ? 'Save' : 'Create'}
              </Button>
              <Button
                onClick={onDrawerClose}
                disabled={savingIntegration || deletingIntegration || loadingIntegration}
                style={buttonStyles}
              >
                Cancel
              </Button>
            </Space>

            {/* Show 'Clear' for integration creation and 'Delete' for deletion */}
            {integrationId ? (
              <Button
                type="danger"
                loading={deletingIntegration}
                disabled={savingIntegration || loadingIntegration}
                onClick={onDelete}
                style={buttonStyles}
              >
                Delete
              </Button>
            ) : (
              <Button type="link" onClick={onReset} disabled={savingIntegration}>
                Clear
              </Button>
            )}
          </div>
        }
      >
        <Spin spinning={loadingIntegration}>
          {/* Integration details */}
          <IntegrationDetailsForm disabledFields={integrationId ? ['type'] : []} />

          <Divider />

          {/* Integration specific auth configurations */}
          <Form.Item dependencies={['type']} noStyle>
            {({ getFieldValue }) => {
              switch (getFieldValue('type')) {
                case 'otsoDeploy':
                  return <OtsoDeployForm disabled={authDisabled} />;
                case 'outlook':
                  return (
                    <OutlookAuthentication
                      authData={customAuthData}
                      setAuthData={setCustomAuthData}
                      disabled={authDisabled}
                    />
                  );
                case 'facebook':
                  return (
                    <FacebookAuthForm
                      authData={customAuthData}
                      setAuthData={setCustomAuthData}
                      disabled={authDisabled}
                    />
                  );
                case 'datashake':
                  return <DatashakeForm disabled={authDisabled} />;
                case 'genesys':
                  return <GenesysForm disabled={authDisabled} />;
                case 'bangthetable':
                  return <BangTheTableForm disabled={authDisabled} />;
                case 'outlookApplication':
                  return <OutlookApplicationForm disabled={authDisabled} />;
                case 'brollyMessages':
                case 'brollyFeed':
                  return <BrollyForm disabled={authDisabled} />;
                case 'epathway':
                  return <EPathwayForm disabled={authDisabled} />;
                default:
                  return null;
              }
            }}
          </Form.Item>

          <Form.Item dependencies={['type']} noStyle>
            {({ getFieldValue }) =>
              ['reddit'].indexOf(getFieldValue('type')) < 0 &&
              authDisabled && (
                <Button onClick={onEditIntegrationAuth} style={buttonStyles}>
                  Edit Authentication
                </Button>
              )
            }
          </Form.Item>
        </Spin>
      </Drawer>
    </Form>
  );
};

export default Integration;
