/**
 * Remove the beginning and the ending '/', convert path string to a list.
 * @param {string} path File path
 * @returns file path in a list format
 */
const constructDirPath = (path) => {
  if (path.startsWith('/')) return constructDirPath(path.substring(1));
  if (path.endsWith('/')) return constructDirPath(path.slice(0, -1));
  return path.split('/');
};

export default constructDirPath;
