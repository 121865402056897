/** @jsxImportSource @emotion/react */

import { useState, useEffect } from 'react';

// Import Ant Design components
import { Form, Select, message } from 'antd';

// Import stylesheets
import { getApi } from '../../../modules/api';

// Import additional Ant Design components
const { Option } = Select;

const GenesysForm = ({ form, initialValues, integrationId }) => {
    // Initialisation
    const [queues, setQueues] = useState([]);

    useEffect(() => {
        // Obtain authorised Facebook pages
        const getQueues = async () => {
            try {
                const { data = {} } = await getApi(`integrations/${integrationId}`);
                setQueues(data.queues || []);
            } catch (error) {
                message.error('Unable to obtain Genesys Queues');
            }
        };

        getQueues();

        return () => setQueues([]);
    }, [integrationId]);

    return (
        <Form name="genesysForm" form={form} initialValues={initialValues} layout="vertical">
            <Form.Item name="genesysQueue" label="Queue ID" rules={[{ required: true }]}>
                <Select allowClear placeholder="Select Queue">
                    {queues.map((queue) => (
                        <Option key={queue.id} value={queue.id}>
                            {queue.name}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        </Form>
    );
};

export default GenesysForm;
