/** @jsxImportSource @emotion/react */

// Import libraries
import { startCase, snakeCase } from 'lodash';
import { useState, useEffect } from 'react';
import { useAuth } from '@otso/auth-wrapper';
import { useHistory, Link } from 'react-router-dom';

// Import Ant Design components
import { Form, Button, Space, Breadcrumb, message } from 'antd';

// Import modules
import { postApi } from '../../../../modules/api';

// Import components
import CollapsableCard from '../CollapsableCard';
import DataSource from '../DataSource';
import DataEnrichments from '../DataEnrichments';

// Import forms
import DatasetDetailsForm from '../../forms/DatasetDetailsForm';

// Import stylesheets
import { button as buttonStyles } from '../../../../styles/presets';
import styles from './styles';

const CreateDataset = () => {
  // Load data from contexts
  const { user } = useAuth();
  const { currentOrg } = user || {};

  // Initialisation
  const history = useHistory();
  const [selectedSource, setSelectedSource] = useState({});
  const [vertexEndpoints, setVertexEndpoints] = useState([]);
  const [selectedEnrichment, setSelectedEnrichment] = useState({});
  const [analysedData, setAnalysedData] = useState(null);
  const [loadingAnalysedData, setLoadingAnalysedData] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [_, setDelayedAnalysisId] = useState(null);
  const [dataSourceStep, setDataSourceStep] = useState(0);
  const [fieldConfigs, setFieldConfigs] = useState(null);
  const [creatingDataset, setCreatingDataset] = useState(false);
  const [collapseStates, setCollapseStates] = useState({
    basicInfo: true,
    dataSource: false,
    dataEnrichments: false,
  });

  // Initialise form
  const [form] = Form.useForm();

  useEffect(() => {
    // Analysis the selected file source
    const analysisFileSource = async () => {
      setLoadingAnalysedData(true);

      // Delay in analysing file source in case source is updated too quickly
      const delayedId = setTimeout(async () => {
        try {
          const { data } = await postApi('storage/analysis', selectedSource);
          setAnalysedData(data);

          // Update field configurations
          const { fileStats = [] } = data || {};
          setFieldConfigs(
            fileStats.reduce(
              (prev, { id, type, field }) => ({
                ...prev,
                [id]: { type, field, displayName: startCase(field) },
              }),
              {}
            )
          );
        } catch (error) {
          message.error(
            'Unable to analyze the selected file source data, please try again later or contact support for help'
          );
        } finally {
          setLoadingAnalysedData(false);
        }
      }, 1000);

      // Update the setTimeout id, clear the existing one if there is one
      setDelayedAnalysisId((currentDelayedId) => {
        if (currentDelayedId) clearTimeout(currentDelayedId);
        return delayedId;
      });
    };

    if (selectedSource.type && selectedSource.type !== 'integration') {
      analysisFileSource();
    }

    return () => {
      setAnalysedData(null);
      setLoadingAnalysedData(false);
      setDelayedAnalysisId(null);
      setFieldConfigs(null);
    };
  }, [selectedSource]);

  // On dataset creation form submitted
  const onFinish = async (__, { values }) => {
    // Perform validations
    if (!selectedSource.name) {
      message.error('Missing dataset source');
    } else if (
      selectedEnrichment.name &&
      selectedSource.type !== 'integration' &&
      !values.verbatimFieldName
    ) {
      message.error('Missing verbatim field');
    } else {
      try {
        setCreatingDataset(true);
        setCollapseStates((states) => ({
          ...states,
          basicInfo: false,
          dataSource: false,
          dataEnrichments: false,
        }));

        const shouldGeolocate = values.shouldGeolocate || values.shouldGeolocateOnIntegrations;
        const geolocation = {
          enabled: shouldGeolocate,
        };
        if (shouldGeolocate && values.verbatimFieldName) {
          geolocation.verbatimFieldName = snakeCase(values.verbatimFieldName);
        }
        if (shouldGeolocate && values.locationFieldName) {
          geolocation.locationFieldName = snakeCase(values.locationFieldName);
        }

        // Create a new dataset and obtain the dataset slug
        const { data } = await postApi('datasets', {
          ...values,
          geolocation,
          selectedSource,
          vertexEndpoints,
          selectedEnrichment,
          fieldConfigs,
        });
        const { datasetSlug } = data || {};

        message.success('Dataset created');
        history.push(`/datasets/${datasetSlug}`);
      } catch (error) {
        setCreatingDataset(false);
        message.error('Unable to create dataset, please try again later');
      }
    }
  };

  // On collapse panel active status changed
  const onActiveChanged = (key) =>
    setCollapseStates((states) => ({ ...states, [key]: !states[key] }));

  // Move on to the next step
  const moveToNext = async (currentKey) => {
    try {
      switch (currentKey) {
        case 'basicInfo': {
          await form.validateFields(['name']);
          setCollapseStates((states) => ({
            ...states,
            basicInfo: false,
            dataSource: true,
          }));
          break;
        }
        case 'dataSource': {
          if (selectedSource.target) {
            if (dataSourceStep === 0) {
              setDataSourceStep(1);
            } else {
              setCollapseStates((states) => ({
                ...states,
                dataSource: false,
                dataEnrichments: true,
              }));
            }
          } else message.warning('Please select a data source');
          break;
        }
        case 'dataEnrichments':
        default:
          break;
      }
    } catch (error) {
      // Mute the error
    }
  };

  const onClickSubmit = () => {
    const { verbatimFieldName, locationFieldName, shouldGeolocate, shouldGeolocateOnIntegrations } =
      form.getFieldsValue();
    if (
      (shouldGeolocate || shouldGeolocateOnIntegrations) &&
      selectedSource.type !== 'integration'
    ) {
      if (!verbatimFieldName && !locationFieldName) {
        message.error(
          'Please specify either Verbatim field or location field to enable Geo-location'
        );
        return;
      }
    }
    form.submit();
  };

  return (
    <div style={styles.createDatasetWrapper}>
      <Breadcrumb style={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Link to="/datasets">Datasets</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Create Dataset</Breadcrumb.Item>
      </Breadcrumb>

      <Form.Provider onFormFinish={onFinish}>
        {/* Dataset details */}
        <CollapsableCard
          sectionKey="basicInfo"
          displayName="Basic Information"
          extra={form.getFieldValue('name')}
          active={collapseStates.basicInfo}
          onActiveChanged={onActiveChanged}
          styles={styles.collapsableCard}
        >
          <DatasetDetailsForm form={form} />
          <div style={styles.nextButtonWrapper}>
            <Button type="primary" onClick={() => moveToNext('basicInfo')} style={buttonStyles}>
              Next
            </Button>
          </div>
        </CollapsableCard>

        {/* Data source and configurations */}
        <CollapsableCard
          sectionKey="dataSource"
          displayName="Data Source"
          extra={selectedSource.name}
          active={collapseStates.dataSource}
          onActiveChanged={onActiveChanged}
          styles={styles.collapsableCard}
        >
          <DataSource
            form={form}
            currentOrg={currentOrg}
            selectedSource={selectedSource}
            setSelectedSource={setSelectedSource}
            analysedData={analysedData}
            loadingAnalysedData={loadingAnalysedData}
            dataSourceStep={dataSourceStep}
            setDataSourceStep={setDataSourceStep}
            fieldConfigs={fieldConfigs}
            setFieldConfigs={setFieldConfigs}
            styles={{
              '.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab': {
                paddingLeft: '0px',
              },
            }}
          />
          <div style={styles.nextButtonWrapper}>
            <Space>
              {dataSourceStep === 1 && (
                <Button onClick={() => setDataSourceStep(0)} style={buttonStyles}>
                  Previous
                </Button>
              )}
              <Button type="primary" onClick={() => moveToNext('dataSource')} style={buttonStyles}>
                Next
              </Button>
            </Space>
          </div>
        </CollapsableCard>

        {/* Data enrichments */}
        <CollapsableCard
          sectionKey="dataEnrichments"
          displayName="Data Enrichments (Optional)"
          active={collapseStates.dataEnrichments}
          onActiveChanged={onActiveChanged}
          styles={styles.collapsableCard}
        >
          <DataEnrichments
            form={form}
            vertexEndpoints={vertexEndpoints}
            setVertexEndpoints={setVertexEndpoints}
            selectedEnrichment={selectedEnrichment}
            setSelectedEnrichment={setSelectedEnrichment}
            selectedSource={selectedSource}
            fieldConfigs={fieldConfigs}
          />
        </CollapsableCard>

        {/* Action panel */}
        <Space style={styles.actionPanel}>
          <Button
            type="primary"
            onClick={onClickSubmit}
            loading={creatingDataset}
            disabled={!selectedSource || !selectedSource.type}
            style={buttonStyles}
          >
            Create
          </Button>
          <Button
            onClick={() => history.push('/datasets')}
            disabled={creatingDataset}
            style={buttonStyles}
          >
            Cancel
          </Button>
        </Space>
      </Form.Provider>
    </div>
  );
};

export default CreateDataset;
