/** @jsxImportSource @emotion/react */

// Import Ant Design components
import { Form, Input } from 'antd';

// Import additional Ant Design components
const { TextArea } = Input;

const OutlookApplication = ({ form, initialValues }) => {
    return (
        <Form
            name="outlookApplicationForm"
            form={form}
            initialValues={initialValues}
            layout="vertical"
        >
            <Form.Item name="outlookMailboxes" label="Mailboxes" rules={[{ required: true }]}>
                <TextArea
                    rows={4}
                    placeholder="A comma seperated list of mailboxes and folders. E.g. mail@internet.com/inbox, mail@internet.com/important, mail2@internet.com/inbox"
                />
            </Form.Item>
        </Form>
    );
};

export default OutlookApplication;
