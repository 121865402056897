/** @jsxImportSource @emotion/react */

// Import Ant Design components
import { Steps } from 'antd';

// Import components
import SourceSelection from '../SourceSelection';
import DataSourceConfigs from './components/DataSourceConfigs';

const { Step } = Steps;

const DataSource = (props) => {
    // Extract values from props
    const {
        form,
        currentOrg,
        selectedSource,
        setSelectedSource,
        analysedData,
        loadingAnalysedData,
        dataSourceStep,
        setDataSourceStep,
        fieldConfigs,
        setFieldConfigs,
        styles = {},
    } = props;

    // Extract values from selected source
    const { type: sourceType } = selectedSource || {};

    return (
        <div css={styles}>
            <Steps
                size="small"
                current={dataSourceStep}
                onChange={(current) => setDataSourceStep(current)}
                style={{ margin: '15px auto 45px', width: '60%' }}
            >
                <Step title="Source Selection" />
                <Step title="Configurations" disabled={!sourceType} />
            </Steps>

            {/* Dataset source selection */}
            {dataSourceStep === 0 && (
                <SourceSelection
                    currentOrg={currentOrg}
                    selectedSource={selectedSource}
                    setSelectedSource={(value) => {
                        setSelectedSource(value);
                        form.resetFields(['idFieldName', 'dateFieldName', 'verbatimFieldName']);
                    }}
                    filteredIntegrationTypes={['otsoDeploy']}
                    tabPosition="left"
                    defaultActiveKey={sourceType === 'integration' ? 'integrations' : 'files'}
                />
            )}

            {/* Dataset source configurations */}
            {dataSourceStep === 1 && (
                <DataSourceConfigs
                    form={form}
                    selectedSource={selectedSource}
                    analysedData={analysedData}
                    loadingAnalysedData={loadingAnalysedData}
                    fieldConfigs={fieldConfigs}
                    setFieldConfigs={setFieldConfigs}
                />
            )}
        </div>
    );
};

export default DataSource;
