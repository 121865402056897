// Import libraries
import axios from 'axios';
import { get } from 'lodash';
import firebase from 'firebase/app';
import 'firebase/auth';

// Load environment variables
const { REACT_APP_DATA_API_URL: API_URL } = process.env;

/**
 * Get user a JSON Web Token (JWT) used to identify the user for calling api services.
 * @returns a JWT token or null (if not exist).
 */
const getUserToken = async () => {
    // Retrieve the currently logged in user
    const { currentUser } = await firebase.auth();
    if (currentUser) return currentUser.getIdToken();
    return null;
};

/**
 * Remove all prefix / in the given url and return the formatted path.
 * @param {string} path Path to be checked and formatted
 * @returns a formatted url
 */
const checkURLPath = (path) => {
    let sliceLength = 0;
    for (let idx = 0; idx < path.length; idx += 1) {
        if (path[idx] === '/') sliceLength += 1;
        else break;
    }
    return path.slice(sliceLength);
};

/**
 * Send a GET request to a backend service or an external service if host is provided.
 * By default, a Firebase JWT token will be sent along with the request.
 * If a different authentication token needs to be sent, supply the authToken value.
 * @param {string} path Backend resource path
 * @param {Object} paramters Request parameters
 * @param {Object} additionalConfigs Additional configurations
 * @returns a request promise to be resolved
 */
export const getApi = async (path, paramters = {}, additionalConfigs = {}) => {
    // Extract additional configurations
    const {
        host = API_URL, // Host address of the external service, default to the backend api host
        authToken = null, // A different authentication token to be sent
        withCredentials = false, // Whether or not cross-site Access-Control requests should be made using credentials
    } = additionalConfigs;

    // Obtain a user token and prepare the request headers
    const token = authToken || (await getUserToken());
    const headers = { Accept: 'application/json' };
    if (token) headers.Authorization = `JWT ${token}`;

    return axios.get(`${host}/${checkURLPath(path)}`, {
        params: paramters,
        headers,
        withCredentials,
    });
};

/**
 * Send a POST request to a backend service or an external service if host is provided.
 * By default, a Firebase JWT token will be sent along with the request.
 * If a different authentication token needs to be sent, supply the authToken value.
 * @param {string} path Backend resource path
 * @param {Object} data Request body
 * @param {Object} additionalConfigs Additional configurations
 * @returns a request promise to be resolved
 */
export const postApi = async (path, data = {}, additionalConfigs = {}) => {
    // Extract additional configurations
    const {
        host = API_URL, // Host address of the external service, default to the backend api host
        authToken = null, // A different authentication token to be sent
        withCredentials = false, // Whether or not cross-site Access-Control requests should be made using credentials
    } = additionalConfigs;

    // Obtain a user token and prepare the request headers
    const token = authToken || (await getUserToken());
    const headers = { Accept: 'application/json' };
    if (token) headers.Authorization = `JWT ${token}`;

    return axios.post(`${host}/${checkURLPath(path)}`, data, {
        headers,
        withCredentials,
    });
};

/**
 * Send a PUT request to a backend service or an external service if host is provided.
 * By default, a Firebase JWT token will be sent along with the request.
 * If a different authentication token needs to be sent, supply the authToken value.
 * @param {string} path Backend resource path
 * @param {Object} data Request body
 * @param {Object} additionalConfigs Additional configurations
 * @returns a request promise to be resolved
 */
export const putApi = async (path, data = {}, additionalConfigs = {}) => {
    // Extract additional configurations
    const {
        host = API_URL, // Host address of the external service, default to the backend api host
        authToken = null, // A different authentication token to be sent
        withCredentials = false, // Whether or not cross-site Access-Control requests should be made using credentials
    } = additionalConfigs;

    // Obtain a user token and prepare the request headers
    const token = authToken || (await getUserToken());
    const headers = { Accept: 'application/json' };
    if (token) headers.Authorization = `JWT ${token}`;

    return axios.put(`${host}/${checkURLPath(path)}`, data, {
        headers,
        withCredentials,
    });
};

/**
 * Send a PUT request to upload a file, with an optional callback to handle the upload progress.
 * @param {string} url URL used to upload the file
 * @param {Object} file File to be uploaded
 * @param {Object} headers Request headers
 * @param {Function} onUploadProgressCB Callback function when upload progress is received
 * @returns a request promise to be resolved
 */
export const uploadApi = async (url, file, headers = {}, onUploadProgressCB = null) => {
    // Prepare headers and a callback function for progressing
    const options = {
        headers,
        onUploadProgress: onUploadProgressCB,
    };

    return axios.put(url, file, options);
};

/**
 * Send a DELETE request to a backend service or an external service if host is provided.
 * By default, a Firebase JWT token will be sent along with the request.
 * If a different authentication token needs to be sent, supply the authToken value.
 * @param {string} path Backend resource path
 * @param {Object} data Request body
 * @param {Object} additionalConfigs Additional configurations
 * @returns a request promise to be resolved
 */
export const deleteApi = async (path, data = {}, additionalConfigs = {}) => {
    // Extract additional configurations
    const {
        host = API_URL, // Host address of the external service, default to the backend api host
        authToken = null, // A different authentication token to be sent
        withCredentials = false, // Whether or not cross-site Access-Control requests should be made using credentials
    } = additionalConfigs;

    // Obtain a user token and prepare the request headers
    const token = authToken || (await getUserToken());
    const headers = { Accept: 'application/json' };
    if (token) headers.Authorization = `JWT ${token}`;

    return axios.delete(`${host}/${checkURLPath(path)}`, {
        data,
        headers,
        withCredentials,
    });
};

/**
 * Optionally retrieve nested error message from response.
 * @param {Error} err API error
 * @param {string} [fallback=undefined] Optional custom fallback error to be returned
 * @returns an error message or a generic error message 'Internal Server Error
 */
export const fetchApiErrorMessage = (err, fallback = undefined) => {
    return get(err, 'response.data.error', fallback || 'Internal Server Error');
};
