// Import libraries
import parser from 'lucene-query-parser';

/**
 * Validate if a boolean query contains correct parentheses.
 * @param {string} queryStr Query string to be validated
 * @returns true if the query has valid parentheses, otherwise false
 */
const validParentheses = (queryStr) => {
    const leftParenthesesCounts = (queryStr.match(/\(/g) || []).length;
    const rightParenthesesCounts = (queryStr.match(/\)/g) || []).length;

    return leftParenthesesCounts === rightParenthesesCounts;
};

/**
 * Validate a boolean query string.
 * @param {*} queryStr Query string to be validated
 * @returns true if the querty is valid, otherwise false
 */
const isBoolQueryValid = (queryStr) => {
    // Validate parentheses
    if (!validParentheses(queryStr)) return false;

    // Use Lucene Query Parser to validate the query
    try {
        parser.parse(queryStr);
    } catch (error) {
        return false;
    }

    return true;
};

export default isBoolQueryValid;
