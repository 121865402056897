// Import presets
import { pageContentWrapper } from '../../styles/presets';

// Prepare a stylesheet
const styles = {
    filesWrapper: {
        ...pageContentWrapper,
        minWidth: '700px',
    },
    drawerFooter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
};

export default styles;
