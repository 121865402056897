/** @jsxImportSource @emotion/react */

// Import Ant Design components
import { Form, Input, Select } from 'antd';

// Import constants
import { integrationTypes } from '../../../constants/integrations';

// Import additional Ant Design components
const { Option } = Select;

const IntegrationDetailsForm = ({ disabledFields = [] }) => {
    return (
        <>
            {/* Integration name */}
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                <Input
                    placeholder="Enter integration name"
                    disabled={disabledFields.indexOf('name') >= 0}
                />
            </Form.Item>

            {/* Integration type */}
            <Form.Item name="type" label="Type" rules={[{ required: true }]}>
                <Select
                    placeholder="Select a integration type"
                    disabled={disabledFields.indexOf('type') >= 0}
                >
                    {integrationTypes.map((integrationType) => (
                        <Option key={integrationType.name}>{integrationType.displayName}</Option>
                    ))}
                </Select>
            </Form.Item>

            {/* Integration description */}
            <Form.Item name="description" label="Description">
                <Input.TextArea
                    rows={3}
                    placeholder="Enter integration description"
                    disabled={disabledFields.indexOf('description') >= 0}
                />
            </Form.Item>
        </>
    );
};

export default IntegrationDetailsForm;
