/** @jsxImportSource @emotion/react */

// Import Ant Design components
import { Card } from 'antd';

// Import stylesheet
import styles from './styles';

const Activity = () => {
    return <Card style={styles.activityWrapper}>Activity</Card>;
};

export default Activity;
