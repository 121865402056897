/** @jsxImportSource @emotion/react */

// Import Ant Design components
import { Modal } from 'antd';

const VersionExport = ({ visible }) => {
    return (
        <Modal title="Version Export" visible={visible} footer={null}>
            <p>Some contents...</p>
            <p>Some contents...</p>
            <p>Some contents...</p>
        </Modal>
    );
};

export default VersionExport;
