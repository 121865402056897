/** @jsxImportSource @emotion/react */

// Import libraries
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import urlJoin from 'url-join';
import pathParse from 'path-parse';
import { find, pick } from 'lodash';
import BBPromise from 'bluebird';
import prettyBytes from 'pretty-bytes';
import { useState, useEffect } from 'react';

// Import Ant Design components
import {
    Space,
    Breadcrumb,
    Typography,
    Table,
    Avatar,
    Button,
    Input,
    Tooltip,
    Empty,
    Divider,
    Upload,
    Modal,
    Alert,
    Badge,
    Progress,
    message,
} from 'antd';
import {
    UploadOutlined,
    ReloadOutlined,
    CloseOutlined,
    FolderAddOutlined,
    WarningOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';

// Import modules
import { deleteApi, postApi } from '../../modules/api';
import {
    listFiles,
    deleteFiles,
    uploadFile,
    getDownloadFileURL,
    checkFileExists,
} from '../../modules/firebase';

// Import utilities
import download from '../../utilities/downloader';
import constructDirPath from '../../utilities/filePath';

// Import stylesheets
import { button as buttonStyles } from '../../styles/presets';
import styles from './styles';

// Import additional Ant Design components
const { Text, Title, Paragraph } = Typography;
const { confirm } = Modal;

const FileBrowser = (props) => {
    // Extract values from props
    const {
        bucket,
        initDirPath = 'data',
        onDirNavigated = null,
        rowSelection = null,
        allowUpload = false,
        allowCreateFolder = false,
        headerStyles = {},
        extraActions = [],
    } = props;

    // Initialisation
    const [dirPath, setDirPath] = useState(initDirPath);
    const [items, setItems] = useState([]);
    const [loadingItems, setLoadingItems] = useState(false);
    const [nextPageToken, setNextPageToken] = useState(null);
    const [refreshedAt, setRefreshedAt] = useState(new Date().getTime());
    const [searchValue, setSearchValue] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [highlightedRowKey, setHighlightedRowKey] = useState(null);

    // States for folder creation
    const [createFolderModalVisible, setCreateFolderModalVisible] = useState(false);
    const [newFolderName, setNewFolderName] = useState(null);
    const [creatingFolder, setCreatingFolder] = useState(false);

    // States for file deletion
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [deleteConfirmText, setDeleteConfirmText] = useState(null);
    const [deletingItems, setDeletingItems] = useState(false);

    // States for file download
    const [fileDownloading, setFileDownloading] = useState(false);

    useEffect(() => {
        setDirPath(initDirPath);
    }, [initDirPath]);

    useEffect(() => {
        const updateDirFileList = async () => {
            try {
                setLoadingItems(true);

                if (searchValue) {
                    const res = await postApi('storage/search', {
                        prefix: urlJoin(dirPath, searchValue),
                    });
                    const { prefixes, items: matchedItems } = res.data || {};

                    const searchResults = [];
                    prefixes.forEach((prefix) => {
                        searchResults.push({
                            contentType: 'folder',
                            name: pathParse(prefix.replace(/\/$/, '')).base,
                            fullPath: prefix,
                        });
                    });
                    matchedItems.forEach((item) => {
                        searchResults.push({
                            contentType: item.contentType,
                            timeCreated: item.timeCreated,
                            size: parseInt(item.size, 10),
                            name: pathParse(item.name).base,
                            fullPath: item.name,
                        });
                    });
                    setItems(searchResults);
                } else {
                    const res = await listFiles(bucket, dirPath);
                    setItems(res.files);
                    setNextPageToken(res.nextPageToken);
                }
            } catch (error) {
                message.error('Unable to load files in the directory');
            }

            setLoadingItems(false);
            setSelectedRowKeys([]);
            setHighlightedRowKey(null);
        };

        if (bucket && dirPath) updateDirFileList();

        return () => {
            setLoadingItems(false);
            setItems([]);
            setNextPageToken(null);
            setSelectedRowKeys([]);
            setHighlightedRowKey(null);
        };
    }, [bucket, dirPath, searchValue, refreshedAt]);

    const refreshFileList = () => setRefreshedAt(new Date().getTime());

    const loadMore = async () => {
        try {
            setLoadingItems(true);
            const res = await listFiles(bucket, dirPath, nextPageToken);
            setItems(items.concat(res.files));
            setNextPageToken(res.nextPageToken);
            setLoadingItems(false);
        } catch (error) {
            message.error('Unable to load more files in the directory');
        }
    };

    /** On creating folder finished or cancelled. */
    const onFinishCreateFolder = () => {
        setCreateFolderModalVisible(false);
        setCreatingFolder(false);
        setNewFolderName(null);
    };

    /** Create an empty folder in the storage. */
    const createFolder = async () => {
        try {
            setCreatingFolder(true);

            // Format the folder path
            let folderPath = [...constructDirPath(dirPath), newFolderName].join('/');
            if (!folderPath.endsWith('/')) folderPath += '/';

            // Send a request to create a folder
            await postApi('storage/folders', { folderPath });

            // Refresh file list
            refreshFileList();
            onFinishCreateFolder();
        } catch (error) {
            setCreatingFolder(false);
            message.error('Unable to create the folder, please try again later');
        }
    };

    /** On deleting items finished or cancelled. */
    const onFinishDeleteItems = () => {
        setDeleteModalVisible(false);
        setDeletingItems(false);
        setDeleteConfirmText(null);
    };

    /** Delete selected items. */
    const deleteItems = async () => {
        try {
            setDeletingItems(true);

            // Prepare a list of item and folder paths to be deleted
            const filePaths = [];
            const folderPaths = [];
            selectedRowKeys.forEach((key) => {
                // Find matched item name
                const { name, contentType } = find(items, { fullPath: key }) || {};
                if (name && contentType) {
                    // Construct the path
                    const itemPath = [...constructDirPath(dirPath), name].join('/');
                    if (contentType === 'folder') {
                        folderPaths.push(itemPath.endsWith('/') ? itemPath : `${itemPath}/`);
                    } else {
                        filePaths.push(itemPath);
                    }
                }
            });

            // Delete selected files
            await deleteFiles(bucket, filePaths);

            // Delete selected folders
            await BBPromise.map(
                folderPaths,
                async (folderPath) => {
                    await deleteApi('storage/folders', { folderPath });
                },
                { concurrency: 1 }
            );

            // Refresh file list
            refreshFileList();
            onFinishDeleteItems();
            setSelectedRowKeys([]);
            setHighlightedRowKey(null);
        } catch (error) {
            setDeletingItems(false);
            message.error('Unable to delete items, please try again later');
        }
    };

    /** Upload a single file. */
    const uploadItem = async (fullPath, file) => {
        // Upload file to the storage
        const uploadTask = uploadFile(bucket, fullPath, file);

        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const onUploadStateChanged = (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setItems((currentItems) =>
                currentItems.map((item) => {
                    if (item.fullPath !== fullPath) return item;
                    return { ...item, timeCreated: { ...item.timeCreated, progress } };
                })
            );
        };

        // Handle unsuccessful file upload
        const onUploadError = () => {
            setItems((currentItems) =>
                currentItems.map((item) => {
                    if (item.fullPath !== fullPath) return item;
                    return { ...item, timeCreated: { status: 'failed' } };
                })
            );
            message.error(`Unable to upload file ${file.name}, please try again later`);
        };

        // Handle successful file upload on complete
        const onUploadCompleted = async () => {
            const metadata = await uploadTask.snapshot.ref.getMetadata();
            setItems((currentItems) =>
                currentItems.map((item) => {
                    if (item.fullPath !== fullPath) return item;
                    return {
                        ...item,
                        ...pick(metadata, ['name', 'size', 'contentType', 'timeCreated']),
                    };
                })
            );

            // Log user's upload activity
            try {
                await postApi('activities', { action: 'upload' });
            } catch (error) {
                // Mute the error
            }
        };

        // Monitor upload progress
        uploadTask.on('state_changed', onUploadStateChanged, onUploadError, onUploadCompleted);
    };

    /** Download a single file.  */
    const downloadFile = async () => {
        try {
            // Locate the matched item
            const matchedItem = find(items, { fullPath: selectedRowKeys[0] });
            const { name, contentType } = matchedItem || {};

            if (selectedRowKeys.length > 1) {
                Modal.info({
                    title: 'File Download',
                    content: (
                        <Paragraph>
                            Only individual objects can be downloaded using the otso Data. It is
                            currently not supported to download multiple objects at a time.
                        </Paragraph>
                    ),
                });
            } else {
                // Initialisation
                let url = null;
                setFileDownloading(true);

                // Prepare item path
                const itemPath = [...constructDirPath(dirPath), name].join('/');

                if (contentType === 'folder') {
                    // Generate a folder download link
                    const res = await postApi('storage/folders/download', {
                        folderPath: itemPath,
                    });
                    const { data } = res || {};
                    ({ signedDownloadUrl: url } = data || {});
                } else {
                    // Generate a file download link
                    url = await getDownloadFileURL(bucket, itemPath);
                }

                // Trigger the file download if url is obtained
                if (url) await download(url, contentType === 'folder' ? `${name}.zip` : name);

                // Clean up and reset
                setSelectedRowKeys([]);
                setFileDownloading(false);
            }
        } catch (error) {
            message.error('Unable to download file, please try again later');
        }
    };

    // Prepare files table columns
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (name, item) => (
                <Space>
                    <Avatar
                        shape="square"
                        size="small"
                        src={
                            item.contentType === 'folder' ? '/icons/folder.png' : '/icons/file.png'
                        }
                    />
                    <Tooltip title={name}>
                        <Text>{name}</Text>
                    </Tooltip>
                </Space>
            ),
        },
        {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
            width: '15%',
            responsive: ['md'],
            render: (size) => (size ? prettyBytes(size) : '-'),
        },
        {
            title: 'Content Type',
            dataIndex: 'contentType',
            key: 'contentType',
            ellipsis: true,
            width: '15%',
            responsive: ['lg'],
            sorter: (a, b) => a.contentType.localeCompare(b.contentType),
        },
        {
            title: 'Time Created',
            dataIndex: 'timeCreated',
            key: 'timeCreated',
            width: '20%',
            responsive: ['lg'],
            sorter: (a, b) => (dayjs(a.timeCreated).isBefore(dayjs(b.timeCreated)) ? -1 : 1),
            render: (timeCreated) => {
                if (typeof timeCreated === 'string')
                    return dayjs(timeCreated).format('MMM DD, YYYY');
                if (typeof timeCreated === 'object') {
                    const { progress, status } = timeCreated;
                    if (status === 'failed') return <Badge status="error" text="Upload failed" />;
                    return (
                        <Progress
                            percent={progress}
                            size="small"
                            format={(percent) => `${parseInt(percent, 10)}%`}
                        />
                    );
                }
                return '-';
            },
        },
    ];

    return (
        <div>
            {/* Header / Batch actions */}
            {selectedRowKeys.length === 0 ? (
                <div style={{ ...styles.header, ...headerStyles }}>
                    {/* Breadcrumb */}
                    <Space>
                        <Text type="secondary">Current Location:</Text>
                        <Breadcrumb css={styles.breadcrumb}>
                            {constructDirPath(dirPath).map((dirPathItem, idx) => (
                                <Breadcrumb.Item
                                    key={nanoid()}
                                    onClick={() => {
                                        const newDirPath = constructDirPath(dirPath)
                                            .slice(0, idx + 1)
                                            .join('/');
                                        if (onDirNavigated) {
                                            onDirNavigated(newDirPath);
                                        } else {
                                            setDirPath(newDirPath);
                                        }
                                    }}
                                >
                                    {dirPathItem}
                                </Breadcrumb.Item>
                            ))}
                        </Breadcrumb>
                    </Space>

                    {/* Action panel */}
                    <Space>
                        {/* Search files or folders by prefix */}
                        <Input.Search
                            placeholder="Search files or folders"
                            onSearch={(value) => setSearchValue(value)}
                            enterButton
                            allowClear
                        />

                        {/* Upload a single or multiple files */}
                        {allowUpload && (
                            <Upload
                                multiple
                                showUploadList={false}
                                beforeUpload={async (file) => {
                                    // Extract values from file
                                    const { name, size, type: contentType } = file;

                                    // Prepare item path
                                    const fullPath = [...constructDirPath(dirPath), file.name].join(
                                        '/'
                                    );

                                    // Prepare item to be uploaded
                                    const itemToUpload = {
                                        name,
                                        size,
                                        contentType,
                                        fullPath,
                                        timeCreated: {
                                            status: 'uploading',
                                            progress: 0,
                                        },
                                    };

                                    const prepareUpload = () => {
                                        // Check if a file with the same name already exists in the file list fetched
                                        const itemNames = items.map((item) => item.name);

                                        if (itemNames.indexOf(name) >= 0) {
                                            // Update the existing file
                                            setItems((currentItems) =>
                                                currentItems.map((item) => {
                                                    if (item.name !== name) return item;
                                                    return itemToUpload;
                                                })
                                            );
                                        } else {
                                            // Append file to the beginning of the files list
                                            setItems((currentItems) => [
                                                itemToUpload,
                                                ...currentItems,
                                            ]);
                                        }

                                        // Trigger the file upload
                                        uploadItem(fullPath, file);
                                    };

                                    // Check if file exists in the current directory
                                    const fileExists = await checkFileExists(bucket, fullPath);

                                    if (fileExists) {
                                        confirm({
                                            title: 'Existing file found',
                                            icon: <ExclamationCircleOutlined />,
                                            content:
                                                'A file with the same name exists, are you sure you want to overwrite?',
                                            onOk: () => prepareUpload(),
                                        });
                                    } else {
                                        prepareUpload();
                                    }

                                    return false;
                                }}
                            >
                                <Button
                                    type="primary"
                                    icon={<UploadOutlined />}
                                    style={buttonStyles}
                                >
                                    Upload File
                                </Button>
                            </Upload>
                        )}

                        {/* Create a new folder */}
                        {allowCreateFolder && (
                            <Tooltip title="Create Folder">
                                <Button
                                    type="text"
                                    size="large"
                                    icon={<FolderAddOutlined />}
                                    onClick={() => setCreateFolderModalVisible(true)}
                                />
                            </Tooltip>
                        )}

                        {/* Refresh the file list */}
                        <Tooltip title="Refresh">
                            <Button
                                type="text"
                                icon={<ReloadOutlined />}
                                onClick={refreshFileList}
                            />
                        </Tooltip>
                    </Space>
                </div>
            ) : (
                <div style={styles.batchActions}>
                    <Button
                        type="text"
                        icon={<CloseOutlined />}
                        onClick={() => setSelectedRowKeys([])}
                        style={{
                            ...styles.clearRowSelectionsButton,
                            ...styles.white,
                        }}
                    />

                    <Space size="middle" split={<Divider style={styles.whiteBg} type="vertical" />}>
                        <Text style={styles.white}>{selectedRowKeys.length} selected</Text>

                        <Space>
                            {extraActions.map((extraAction) => (
                                <Button
                                    key={extraAction.name}
                                    type="ghost"
                                    style={styles.white}
                                    onClick={() =>
                                        extraAction.onClick(
                                            items.filter(
                                                (item) =>
                                                    selectedRowKeys.indexOf(item.fullPath) >= 0
                                            )
                                        )
                                    }
                                >
                                    {extraAction.label}
                                </Button>
                            ))}
                            <Button
                                type="ghost"
                                style={styles.white}
                                onClick={downloadFile}
                                loading={fileDownloading}
                            >
                                Download
                            </Button>
                            <Button
                                type="ghost"
                                style={styles.white}
                                onClick={() => setDeleteModalVisible(true)}
                            >
                                Delete
                            </Button>
                        </Space>
                    </Space>
                </div>
            )}

            {/* File list */}
            <Table
                dataSource={items}
                columns={columns}
                pagination={false}
                rowKey="fullPath"
                loading={loadingItems}
                locale={{
                    emptyText: <Empty description="No files found" style={styles.empty} />,
                }}
                onRow={(record) => ({
                    onClick: () => setHighlightedRowKey(record.fullPath),
                    onDoubleClick: (e) => {
                        if (record.contentType === 'folder' && e.target.type !== 'checkbox') {
                            if (onDirNavigated) {
                                onDirNavigated(urlJoin(dirPath, record.name));
                            } else {
                                setDirPath(urlJoin(dirPath, record.name));
                            }
                        }
                    },
                })}
                rowSelection={
                    rowSelection || {
                        selectedRowKeys,
                        onChange: (selectedKeys) => setSelectedRowKeys(selectedKeys),
                    }
                }
                rowClassName={(record) =>
                    record.fullPath === highlightedRowKey ? 'highlighted-table-row' : null
                }
                css={styles.filesTable}
            />

            {/* Load more */}
            {nextPageToken && (
                <div style={styles.loadMore}>
                    <Button type="link" onClick={loadMore}>
                        Load More
                    </Button>
                </div>
            )}

            {/* Modal for creating folder */}
            <Modal
                visible={createFolderModalVisible}
                closable={false}
                onOk={createFolder}
                okButtonProps={{ loading: creatingFolder }}
                okText="Add folder"
                onCancel={onFinishCreateFolder}
                cancelButtonProps={{ disabled: creatingFolder }}
            >
                <Space size="middle" direction="vertical" style={styles.folderNameModalWrapper}>
                    <Title level={5}>Folder Name</Title>
                    <Input
                        value={newFolderName}
                        onChange={(e) => setNewFolderName(e.target.value)}
                        placeholder="Enter the new folder name"
                        style={styles.folderNameInput}
                    />
                </Space>
            </Modal>

            {/* Modal for deleting items */}
            <Modal
                visible={deleteModalVisible}
                closable={false}
                onOk={deleteItems}
                okButtonProps={{
                    type: 'danger',
                    loading: deletingItems,
                    disabled: deleteConfirmText !== 'DELETE',
                }}
                okText="Delete"
                onCancel={onFinishDeleteItems}
                cancelButtonProps={{ disabled: deletingItems }}
            >
                <Space size="middle" direction="vertical">
                    <Title level={5}>Delete files</Title>
                    <Alert
                        message="Warning"
                        description="You may be deleting user data. After you delete this, it can't be recovered."
                        type="error"
                        showIcon
                        icon={<WarningOutlined />}
                    />
                    <Input
                        value={deleteConfirmText}
                        onChange={(e) => setDeleteConfirmText(e.target.value)}
                        placeholder="Confirm deletion by typing DELETE"
                        style={styles.folderNameInput}
                    />
                </Space>
            </Modal>
        </div>
    );
};

export default FileBrowser;
