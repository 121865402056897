/** @jsxImportSource @emotion/react */

// Import libraries
import urlJoin from 'url-join';
import { useState } from 'react';
import { useAuth } from '@otso/auth-wrapper';

// Import Ant Design components
import { Space, Button, Modal, Input, Typography, message } from 'antd';

// Import components
import SourceSelection from '../SourceSelection';

// Import modules
import { postApi } from '../../../../modules/api';

// Import utilities
import constructDirPath from '../../../../utilities/filePath';

// Import stylesheet
import styles from './styles';

// Import additional Ant Design components
const { Paragraph } = Typography;
const { TextArea } = Input;

const DatasetSource = (props) => {
    // Extract values from props
    const { datasetSlug, type, sourceType, source, onUpdateDataset, createDatasetVersion } = props;
    const {
        user: { currentOrg },
    } = useAuth();

    // Prepare the initial directory path
    const initDirPath =
        sourceType !== 'integration' ? constructDirPath(source).slice(0, -1).join('/') : 'data';

    // Initialisation
    const [selectedSource, setSelectedSource] = useState({
        target: source,
        type: sourceType,
    });
    const [modalVisible, setModalVisible] = useState(false);
    const [versionDescription, setVersionDescription] = useState(null);
    const [saving, setSaving] = useState(false);

    // On saving or cancelling the new version creation
    const onModalFinish = () => {
        setModalVisible(false);
        setVersionDescription(null);
    };

    // On saving the source and source type changes
    const onSave = async () => {
        try {
            setSaving(true);
            await onUpdateDataset({
                source: selectedSource.target,
                sourceType: selectedSource.type,
            });

            // Create a new dataset version
            await createDatasetVersion(versionDescription);

            // Set up the new dataset version
            await postApi(urlJoin('datasets', datasetSlug, 'setup'));
            message.success('Dataset source updated');

            onModalFinish();
        } catch (error) {
            message.error('Unable to save the new source location, please try again later');
        }
        setSaving(false);
        onModalFinish();
    };

    // On resetting the selected source
    const onReset = () => setSelectedSource({ ...selectedSource, target: source });

    return (
        <div>
            {/* Dataset source selection */}
            <SourceSelection
                currentOrg={currentOrg}
                datasetType={type}
                selectedSource={selectedSource}
                setSelectedSource={setSelectedSource}
                tabPosition="left"
                tabBarStyle={{ width: '160px' }}
                defaultActiveKey={sourceType === 'integration' ? 'integrations' : 'files'}
                initDirPath={initDirPath}
                filteredIntegrationTypes={['otsoDeploy']}
            />

            {/* Action panel */}
            {selectedSource.target !== source && (
                <Space style={styles.actionPanel}>
                    <Button type="primary" onClick={() => setModalVisible(true)} loading={saving}>
                        Save
                    </Button>
                    <Button onClick={onReset} disabled={saving}>
                        Reset
                    </Button>
                </Space>
            )}

            {/* New version modal */}
            <Modal
                title="New Dataset Version"
                visible={modalVisible}
                closable={false}
                onOk={onSave}
                okButtonProps={{ loading: saving }}
                onCancel={onModalFinish}
                cancelButtonProps={{ disabled: saving }}
            >
                <Paragraph>
                    This action will create a new dataset version. Please enter a dataset version
                    description below (optional).
                </Paragraph>
                <TextArea
                    row={3}
                    placeholder="Enter version description"
                    value={versionDescription}
                    onChange={(e) => setVersionDescription(e.target.value)}
                />
            </Modal>
        </div>
    );
};

export default DatasetSource;
