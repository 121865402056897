/** @jsxImportSource @emotion/react */

// Import Ant Design components
import { Form, Input } from 'antd';

const OtsoDeployForm = ({ disabled = false }) => {
    return (
        <>
            {/* otso Deploy url */}
            <Form.Item
                name="url"
                label="URL"
                rules={[{ required: true }, { type: 'url', warningOnly: true }]}
            >
                <Input placeholder="Enter otso Deploy url" disabled={disabled} />
            </Form.Item>

            {/* otso Deploy api key */}
            <Form.Item name="apiKey" label="API Key" rules={[{ required: true }]}>
                <Input placeholder="Enter otso Deploy api key" disabled={disabled} />
            </Form.Item>
        </>
    );
};

export default OtsoDeployForm;
