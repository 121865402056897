/** @jsxImportSource @emotion/react */

// Import Ant Design components
import { Form, Input } from 'antd';

const GenesysForm = ({ disabled = false }) => {
    return (
        <>
            <Form.Item name="clientId" label="Client ID" rules={[{ required: true }]}>
                <Input placeholder="Enter Genesys Cloud Client ID" disabled={disabled} />
            </Form.Item>
            <Form.Item name="clientSecret" label="Client Secret" rules={[{ required: true }]}>
                <Input placeholder="Enter Genesys Cloud Client Secret" disabled={disabled} />
            </Form.Item>
            <Form.Item name="cloudRegion" label="Cloud Region" rules={[{ required: true }]}>
                <Input placeholder="Enter Genesys Cloud Region" disabled={disabled} />
            </Form.Item>
        </>
    );
};

export default GenesysForm;
