/** @jsxImportSource @emotion/react */

// Import Ant Design components
import { Card, Typography } from 'antd';

// Import stylesheet
import styles from './styles';

// Import additional Ant Design components
const { Title } = Typography;

const Activities = () => {
    return (
        <Card style={styles.activitiesWrapper}>
            <Title level={4}>Activities</Title>
        </Card>
    );
};

export default Activities;
