// Prepare a stylesheet
const styles = {
    uploadListItem: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    uploadListItemInfo: { width: '85%' },
};

export default styles;
