// Prepare a stylesheet
const styles = {
    empty: { padding: '10px 0px' },
    emailListItem: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    emailListAvatar: {
        width: '15%',
    },
    emailListItemInfo: {
        display: 'flex',
        flexDirection: 'column',
        width: '85%',
    },
    subject: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    emailDetails: {
        padding: '20px 15px',
        backgroundColor: '#F9F9FB',
    },
    emailsTable: {
        tbody: { 'tr:hover': { cursor: 'pointer' } },
        '.highlighted-table-row': { backgroundColor: '#E1F0FF' },
    },
};

export default styles;
