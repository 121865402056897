// Import libraries
import { find } from 'lodash';

/** Export a list of integration types. */
export const integrationTypes = [
  { name: 'outlook', displayName: 'Microsoft Outlook' },
  { name: 'otsoDeploy', displayName: 'otso Deploy' },
  { name: 'datashake', displayName: 'Datashake' },
  // { name: 'reddit', displayName: 'Reddit' },
  // { name: 'facebook', displayName: 'Facebook' },
  { name: 'genesys', displayName: 'Genesys' },
  { name: 'outlookApplication', displayName: 'Microsoft Outlook (Application)' },
  { name: 'bangthetable', displayName: 'BangTheTable / EngagementHQ' },
  { name: 'brollyMessages', displayName: 'Brolly Messages' },
  { name: 'brollyFeed', displayName: 'Brolly Feed' },
  { name: 'epathway', displayName: 'E Pathway' },
];

/** Find the integration display type name by the integration type name. */
export const getIntegrationDisplayTypeName = (name) => {
  // Find the integration type, matched by the integration type name
  const matchedIntegrationType = find(integrationTypes, { name }) || {};

  return matchedIntegrationType.displayName;
};
