// Import colors
import colors from '../../styles/colors';

// Prepare stylesheet
const styles = {
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '15px 0px',
    },
    batchActions: {
        display: 'flex',
        padding: '19px 0px',
        color: '#FFFFFF',
        backgroundColor: colors.otsoBrightBlue,
    },
    breadcrumb: { '.ant-breadcrumb-link': { cursor: 'pointer' }},
    clearRowSelectionsButton: { marginRight: '30px' },
    loadMore: {
        marginTop: '30px',
        width: '100%',
        textAlign: 'center',
    },
    empty: { padding: '10px 0px' },
    filesTable: {
        tbody: { 'tr:hover': { cursor: 'pointer' } },
        '.highlighted-table-row': { backgroundColor: '#FBFBFB' },
    },
    white: { color: '#FFFFFF' },
    whiteBg: { backgroundColor: '#FFFFFF' },
    folderNameModalWrapper: { width: '100%' },
    folderNameInput: { width: '100%' },
};

export default styles;
