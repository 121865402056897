/** @jsxImportSource @emotion/react */

// Import Ant Design components
import { Form, Input } from 'antd';

const BrollyForm = ({ disabled = false }) => {
  return (
    <>
      <Form.Item name="apiKey" label="API Key" rules={[{ required: true }]}>
        <Input placeholder="Enter API Key" disabled={disabled} />
      </Form.Item>
      <Form.Item name="apiSecret" label="API Secret" rules={[{ required: true }]}>
        <Input placeholder="Enter API Secret" disabled={disabled} />
      </Form.Item>
    </>
  );
};

export default BrollyForm;
