/** @jsxImportSource @emotion/react */

// Import Ant Design components
import { Form, Input, Button, Row, Col, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

// Import stylesheets
import { button as buttonStyles } from '../../../styles/presets';

// Import additional Ant Design components
const { Option } = Select;

// Prepare a list of Datashake profile types
const profileTypes = [
    { name: 'placeId', label: 'Place ID' },
    { name: 'url', label: 'URL' },
    { name: 'query', label: 'Query' },
];

const DatashakeForm = ({ form, initialValues }) => {
    return (
        <Form name="datashakeForm" form={form} initialValues={initialValues} layout="vertical">
            <Form.List name="profiles">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                            <Row key={key} gutter={16}>
                                {/* Datashake Profile */}
                                <Col span={14}>
                                    <Form.Item
                                        {...restField}
                                        label="Profile"
                                        name={[name, 'profileValue']}
                                        fieldKey={[fieldKey, 'profileValue']}
                                        rules={[{ required: true, message: 'Missing profile' }]}
                                    >
                                        <Input
                                            placeholder="Enter review profile"
                                            addonBefore={
                                                <Form.Item name={[name, 'profileType']} noStyle>
                                                    <Select style={{ width: '100px' }}>
                                                        {profileTypes.map((profileType) => (
                                                            <Option
                                                                key={profileType.name}
                                                                value={profileType.name}
                                                            >
                                                                {profileType.label}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            }
                                        />
                                    </Form.Item>
                                </Col>

                                {/* Query Term */}
                                <Col span={8}>
                                    <Form.Item
                                        {...restField}
                                        label="Query Term"
                                        name={[name, 'queryTerm']}
                                        fieldKey={[fieldKey, 'queryTerm']}
                                        rules={[{ required: true, message: 'Missing query term' }]}
                                    >
                                        <Input placeholder="Enter query term" />
                                    </Form.Item>
                                </Col>

                                {/* Button for removing the profile */}
                                <Col span={2} style={{ display: 'flex', alignItems: 'center' }}>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                </Col>
                            </Row>
                        ))}

                        {/* Buttons for adding new profiles */}
                        <Form.Item>
                            <Button
                                type="dashed"
                                block
                                onClick={() => add({ profileType: 'placeId' })}
                                style={buttonStyles}
                                icon={<PlusOutlined />}
                            >
                                Add New Profile
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </Form>
    );
};

export default DatashakeForm;
